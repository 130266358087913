/* ============================== */

.ui-library-topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
}

.ui-library-topbar > span {
  height: 80px;
}

.ui-library-topbar__home-button {
  outline: none;
  background: unset;
  border: none;
  cursor: pointer;
}