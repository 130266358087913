.AnswerType {
  width: 10%;
  height: calc(100vh - 80px - 70px);
  position: fixed;
  display: flex;
}

.AnswerType ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.AnswerType li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #434B65;
}

.AnswerType-icon {
  font-size: 32px;
}
