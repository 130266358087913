.Log {
  display: flex;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  /* justify-content: space-between; */
}

.Log-login {
  text-decoration: underline;
}

.Log-createdAt {
  font-weight: 800;
}