body {
  height: calc(100% - 70px);
  /* This value correspond to the height of the topBar (which is fixed)*/
  background-color: #F9F9F9;
  font-size: 14px
}

html, #root {
  height: 100%;
}

html {
  font-size: 16px
}

.primary {
  background-color: #3B86FF;
  color: #FFF;
}

.DateRangePickerInput {
  height: 36px;
}

.pageContainer {
  padding: 20px 30px 120px;
}

.ui.bottom.attached.segment.active.tab {
  min-height: 200px;
}

.ui.toggle.checkbox input:checked~.box:after,
.ui.toggle.checkbox input:checked~label:after,
.ui.toggle.checkbox input~.box:after,
.ui.toggle.checkbox input~label:after {
  margin-top: 0px;
}

/* I have no choice but to use this shitty important because
the toggle button in react semantic ui don't provide a style props and include a native !important
*/
.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
  background-color: #3b86ff !important ;
}

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #3b86ff !important;
}


@font-face {
  font-family: Rubik;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src:
    local(rubik-light),
    url(./assets/fonts/Rubik-Light.woff2) format('woff2'),
    url(./assets/fonts/Rubik-Light.woff) format('woff'),
    url(./assets/fonts/Rubik-Light.ttf) format('truetype');
}

@font-face {
  font-family: Rubik;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    local(rubik-regular),
    url(./assets/fonts/Rubik-Regular.woff2) format('woff2'),
    url(./assets/fonts/Rubik-Regular.woff) format('woff'),
    url(./assets/fonts/Rubik-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Rubik;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src:
    local(rubik-medium),
    url(./assets/fonts/Rubik-Medium.woff2) format('woff2'),
    url(./assets/fonts/Rubik-Medium.woff) format('woff'),
    url(./assets/fonts/Rubik-Medium.ttf) format('truetype');
}
