.link-intents-table {
  color: #F9F9F9;
  background-color: #3B86FF;
  padding: 5px 15px;
  border-radius: 15px;
}

.link-intents-table:hover {
  color: white;
  background-color: #87B5FF;
  padding: 5px 15px;
  border-radius: 15px;
}

.ui.sortable.table thead th {
  background-color: #f9F9F9;
  border: none;
}

.ui.sortable.table thead th:first-child {
  width: 20%;
}

.ui.sortable.table thead th:nth-child(3) {
  width: 15%;
}

.ui.sortable.table thead th:last-child {
  width: 10%;
}

.ui.table {
  border: none;
}

.ui.table tr td {
  padding-top: 14px;
  padding-bottom: 14px;
}
