.DateInput {
  width: 62px;
  font-size: 1em;
  padding: 3px;
  line-height: 13px;
  background-color: #3B86FF;
}

.DateRangePickerInput {
  border-radius: .28571429em;
  border: 0px;
  background-color: #3B86FF;
  padding: 1px;
}

.DateInput_displayText__hasInput, .DateRangePickerInput_arrow_svg {
  color: white;
  fill: white;
}

.DateInput_displayText__focused {
  background-color: #3B86FF;
}

.DateRangePicker_picker__openDown {
  top: 50px;
}

.DateInput__openDown:after, .DateInput__openDown:before {
  display: none;
}

.DayPicker_weekHeader {
    top: 55px;
}

.DateInput_input {
  cursor: pointer;
}

.CalendarDay__selected_span {
  background-color: #c6dafc;
  border-color: #c6dafc;
  color: #3B86FF;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  background-color: #3B86FF;
  border-color: #3B86FF;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background-color: #3B86FF;
  border-color: #3B86FF;
  color: white
}

.ui.search.dropdown>.text {
  color: #434B65;
}

.ui.search.dropdown>.dropdown.icon:before {
  color: #434B65 !important;
}
