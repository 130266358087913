.appsettings {
  max-width: 48.813em;
}

.appsettings__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.appsettings__content {
  padding: 1.8em 2em;
  border-radius: 8px;
  border: solid 1px #e7e7e7;
  background-color: #ffffff;
}

.appsettings__title {
  font-size: 1.75em;
  color: #434b65;
}

.appsettings__subtitle {
  font-size: 1em;
  font-weight: normal;
  color: #bababa;
}

.appsettings__subtitlelink {
  text-decoration: underline;
  color: inherit;
}

.appsettings__inputlabel {
  font-size: 0.765em;
  font-weight: bold;
  color: #1a2b63;
}

.appsettings__input {
  display: block;
  width: 100%;
  padding: 0.75em 1em;
  border-radius: 6.5px;
  border: solid 0.7px #ececec;
  background-color: #ffffff;
  font-size: 0.765em;
  color: #434b65;
}

.appsettings__checkbox{
  float: right;
}