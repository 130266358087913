.QuickReply_old {
  display: flex;
  flex-direction: column;
  background-color: #E5E8E8;
  padding: 16px;
  margin: 16px 0;
}

.QuickReply_old-delete {
  display: flex;
  justify-content: flex-end;
}

.QuickReply_old-text {
  display: flex;
  padding: 8px;
}

.QuickReply_old-buttons {
  display: flex;
  flex-direction: column;
}

.QuickReply_old-buttons--button {
  display: flex;
  align-items: center;
  padding: 8px;
}

.QuickReply_old-buttons--button---reply {
  margin-right: 4px;
}

.QuickReply_old-buttons--button---payload {
  margin-left: 4px;
}

.QuickReply_old-buttons--button---delete {
  position: absolute;
  right: 22px;
  transition: all 0.2s;
}

.QuickReply_old-buttons--button---delete:hover,
.QuickReply_old-delete:hover {
  cursor: pointer;
  color: #DD4F75;
}

.QuickReply_old-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.QuickReply_old-actions--addQuickReply_old {
  color: rgb(59, 134, 255);
}

@media (max-width: 960px) {
  .QuickReply_old-actions {
    flex-wrap: wrap;
  }
}
