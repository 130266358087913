.topbar {
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  background-color: #fff;
}

.topbar__logo {
  width: 5.72em;
  margin-left: 4.29em;
  margin-top: 1em;
  margin-bottom: 1em;
}