.SimpleText {
  background-color: #E5E8E8;
  margin: 16px 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.SimpleText-delete {
  display: flex;
  justify-content: flex-end;
}

.SimpleText-delete:hover {
  cursor: pointer;
  color: #DD4F75;
}

.SimpleText-text {
  padding: 8px;
}

@media (max-width: 960px) {
  .SimpleText {
    flex-wrap: wrap;
  }
}
