.loadingContainer {
  margin-top: 50px;
  min-height: 100%;
  background-color: white;
}

.headerDiv {
  background-color: #f2f3f8;
  height: 100%;
}

.mainContainer {
  margin-top: 70px;
  height: 100%;
  background-color: #f9f9f9;
}

.App-content {
  position: relative;
  left: 214px;
  width: calc(100% - 214px);
  height: 100%;
  overflow-y: auto;
  font-family: 'Open Sans', Helvetica, Arial;
  overflow-x: hidden;
}

.App-content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

.App-navbarMenu {
  width: 240px;
}

.mainContent {
  min-height: 100%;
}

/* uiLibrary table property not the same from workspace to bot */
tbody > tr > td >span {
  box-sizing: content-box;
}
