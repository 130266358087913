.TextInput {
  border: none;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  outline: none;
  padding: 0 12px;
  background: none;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

.TextInput-container {
  height: 2.5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-evenly;
  border: 1px solid transparent;
  transition: all 0.3s;
  background: white;
}

.TextInput-decorator {
  padding-right: 12px;
  line-height: 1em;
}

.TextInput-decorator:hover {
  cursor: pointer;
}

.TextInput:focus + div.TextInput-decorator {
  color: #6ba4ff
}

.TextInput-border {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
}

.TextInput-border:focus-within {
  border: 1px solid #6ba4ff;
}

.trainingQuestion-label>.TextInput-container>.TextInput {
  padding: 0;
}

.TextInput-rounded {
  border-radius: 8px;
  border: solid 1px #ececec;
}

.TextInput-rounded:focus-within {
  border: solid 1px #6ba4ff;
}

.TextInput::placeholder {
  color: #B2B2B2;
}
