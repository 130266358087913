.Profile-text {
  font-family: 'Open Sans', Helvetica, Arial;
  font-weight: bold;
  font-size: 14px;
  color: #434b65;
}

.Profile-icon {
  position: relative;
  right: 25px;
  top: 14px;
  color: #ececec;
}

.Profile-icon-active {
  color: #3a86ff;
}

.Profile-input {
  width: 100%;
}

.Profile-input .ui.input input {
  border-radius: 8px;
}

.Profile-button {
  display: flex;
}

.PasswordPage .ui.form .field>label {
  display: none;
}

.PasswordPage .ui.form>:first-child {
  margin-bottom: 20px;
}

.PasswordPage .ui.form .field {
  padding-bottom: 4px;
}

.PasswordPage .ui.button {
  background: #6ba4ff;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.PasswordPage .ui.button>p {
  padding-right: 10px;
  margin: 0;
}

.PasswordField-container {
  position: relative;
}

.PasswordField-icon {
  position: absolute;
  left: 98%;
  top: 10px;
}

.Profile-password-instruction {
  font-style: italic;
  font-size: 12px;
}