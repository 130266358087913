.Answers {
  display: flex;
}

.Answers-content {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 20%;
  background-color: #FFF;
  padding: 16px;
  width: calc(100% - 360px);
  box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 4px;
}

.Answers-content--submit {
  display: flex;
  justify-content: space-between;
}

.Answers-content--submit---cancelButton,
.Answers-content--submit---saveButton {
  width: 100px;
}

.Answers-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
}
