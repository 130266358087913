.TextArea {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid transparent;
  line-height: 24px;
  overflow: auto;
  resize: none;
  outline: none;
  padding: 4px 12px;
  background: white;
  transition: all 0.3s;
}

.TextArea-border {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
}

.TextArea:focus-within {
  border: 1px solid #96c8da;
}

.TextArea-rounded {
  border-radius: 8px;
  border: solid 1px #ececec;
}

.TextArea::placeholder {
  color: #B2B2B2;
}
