.ui.checkbox .box:before, .ui.checkbox label:before, .ui.checkbox .box:after, .ui.checkbox label:after {
  height: 24px;
  width: 24px;
  font-size: 17px;
}

.ui.checkbox {
  min-height: 24px;
  min-width: 24px;
}

.ui.checkbox label:after {
  margin-top: 3px;
}

.ui.checkbox input:focus~.box:before, .ui.checkbox input:focus~label:before {
  border-color: #3B86FF;
}

.ui.checkbox input:checked:focus~.box:before, .ui.checkbox input:checked:focus~label:before, .ui.checkbox input:not([type=radio]):indeterminate:focus~.box:before, .ui.checkbox input:not([type=radio]):indeterminate:focus~label:before {
  border-color: #3B86FF;
}
