.ConversationsList-global {
  display: flex;
  max-width: 500px;
  padding: 20px 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: white;
  border: 1px lightgrey solid;
  align-items: center;
}

.ConversationsList-global > *:not(:last-child) {
  margin-right: 20px;
}

.ConversationsList-global--intent {
  flex: 1;
}

.ConversationsList-listItem {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.ConversationsList-listItem--intent {
  width: 17%;
}

.ConversationsList-listItem--intent-label {
  background-color: #3B86FF;
  border-radius: 15px;
  padding: 4px 16px;
  color: white;
  margin-left: 10px;
  display: inline-block;
}

.ConversationsList-listItem--text {
  width: 50%;
  margin: 0 10px;
}

.ConversationsList-listItem--text-annotations {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.ConversationsList-listItem--text-annotations > *:not(:last-child) {
  margin-right: 6px;
}

.ConversationsList-listItem--text-flag {
  font-size: 1.4em;
  vertical-align: initial;
  text-decoration: inherit;
}

.ConversationsList-listItem--text-annotations-image {
  height: 16px;
  margin-right: 5px;
}

.ConversationsList-listItem--text-annotations-date {
  width: auto;
  font-size: 9px;
  color: #8080807a;
}

.ConversationsList-listItem--train {
  margin-right: 20px;
  color: #4888F7;
}
