.channelcard {
  flex-direction: column;
  width: 18em;
  height: 18em;
  border-radius: 0.6em;
  border: solid 0.1em #d9d9d9;
  background-color: #fff;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
}

.channelcard--disabled {
  opacity: 0.66;
  cursor: not-allowed;
}

.channelcard__icon {
  display: block;
  width: 6em;
  height: 6em;
  margin-top: 1em;
  margin-right: auto;
  margin-left: auto;
}

.channelcard__button,
.channelcard__infotext {
  margin-top: 1.2em;
}

.channelcard__title {
  height: 2.2em;
  margin: 0;
  margin-top: 0.5em;
  text-align: center;
  font-size: 1.7em;
  font-weight: bold;
  color: var(--dusk);
}

.channelcard__button {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.channelcard__button .ButtonLink {
  padding: 0.9em 1.6em;
  text-transform: uppercase;
  font-weight: bold;
}

.channelcard__button + .channelcard__infotext {
  margin-top: 0.5em;
}

.channelcard__infotext {
  padding: 1.1em 1.6em;
  text-align: center;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: bold;
}