.Select {
    position: relative;
    min-width: 250px;
}

.Select-control {
    display: flex;
    border-radius: 4px;
    border: 1px solid rgba(34, 36, 38, .15);
    padding: .67857143em 2.1em .67857143em 1em;
    min-height: 36px;
    align-items: center;
}

.Select-icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 3.5em;
  margin-right: 0.5em;
}

.Select-control--selectedText {
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.Select-control--selectedText---text {
    display: flex;
    text-overflow: ellipsis;
}

.Select-control--faCaret, .Select-control--cross {
    position: absolute;
    right: 12px;
    opacity: 0.7;
}

.Select-control--cross {
    font-size: 1.5em;
}

.Select-control--open {
    border-color: #96c8da;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.Select-control--placeholder {
    color: rgba(191,191,191,.87);
}

.Select-control:hover {
    border-color: rgba(100,100,100,.6);
}

.Select-control--open:hover {
    border-color: #96c8da;
}

.Select-options {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    border: 1px solid #96c8da;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    max-height: 180px;
    overflow-y: auto;
}

.Select-wrapper {
    position: relative;
}

.Select-textInput {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    height: 32px;
    border-top: 1px solid #F2F2F2;
    border-left: 1px solid #96c8da;
    border-right: 1px solid #96c8da;
}

.Select-textInput:focus {
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    border-left: 1px solid #96c8da;
    border-right: 1px solid #96c8da;
}
