.channelslist__title {
  margin: 0;
  margin-top: 1em!important;
  font-size: 2em;
}

.channelslist__subtitle,
.channelslist__listtitle {
  margin: 0;
  font-family: 'Open Sans', Helvetica, Arial;
  font-size: 1.15em;
  font-weight: normal;
}

.channelslist__subtitle {
  margin-top: 0.5em;
  color: #bababa;
}

.channelslist__listtitle {
  margin-top: 3.5em;
  color: #434B65;
}

.channelslist__list {
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
}

.channelslist__listitem {
  margin-top: 1.5em;
  margin-right: 1.5em;
}

.channelslist__listitem--agentstatus>article {
  height: 18em;
  width: 18em;
  display: flex;
  flex-direction: column;
}

.channelslist__listitem--agentstatus>article>h1 {
  height: 2em;
  margin: 0;
  font-size: 1.7em;
  margin-bottom: 20px;
}

.channelslist__listitem--agentstatus>article>svg {
  height: 6em;
  margin-top: 1em;
  margin-bottom: 12px;
}

.channelslist__listitem--agentstatus>article>p {
  font-size: 0.9em;
  margin-top: 1em;
  display: flex;
  align-items: center;
}