.AnswerButton {
  display: flex;
  flex-direction: column;
  background-color: #E5E8E8;
  margin: 16px 0;
  padding: 16px;
}

.AnswerButton-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.AnswerButton-row--title {
  margin-right: 4px;
}

.AnswerButton-row--url {
  margin-left: 4px;;
}

.AnswerButton-actions {
  display: flex;
  justify-content: flex-end;
}

.AnswerButton-actions--delete:hover {
  color: #DD4F75;
  cursor: pointer;
}

@media (max-width: 960px) {
  .AnswerButton, .AnswerButton-row {
    flex-wrap: wrap;
  }

  .AnswerButton-row > * {
    margin-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
