.Option {
  padding: .67857143em 2.1em .67857143em 1em;
  display: flex;
  background-color: #FFF;
  cursor: pointer;
  overflow: hidden;
}

.Option-striped {
  background-color: #f8f8f8;
}

.Option-text {
  text-overflow: ellipsis;
}

.Option-icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 3.5em;
  margin-right: 0.5em;
}

.Option:hover {
    background-color: #F0F0F0;
}
