@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
.ui.message.Error {
    position: fixed;
    left: 20px;
    bottom: 20px;
    background-color: #fff6f6;
}

.ui.message.Error.floating-style {
    box-shadow: 0 0 0 1px rgba(159,58,59,.22) inset, 0 2px 4px 0 rgba(159,58,59,.12), 0 2px 10px 0 rgba(159,58,59,.15);
}

.Loading-Loader {
    background: none;
    height: 300px;
}

.Loading-Empty {
    font-weight: 500;
    font-size: 1.7em;
    width: 100%;
    text-align: center;
    line-height: 2em;
}

.Loading-Loader {
    background: none;
    position: center;
}

.DotLoader {
    display: flex;
    align-items: center;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 600;
    color: #aaaaaa;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 54px;
    height: 8px;
}
.lds-ellipsis div {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #aaaaaa;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 6px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 6px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 23px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 38px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes lds-ellipsis1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@-webkit-keyframes lds-ellipsis3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}
@-webkit-keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(16px, 0);
                transform: translate(16px, 0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(16px, 0);
                transform: translate(16px, 0);
    }
}

.webchat-preview-box, #bot-frame {
  width: 300px;
  height: 408px;
  border-radius: 10px;
  box-shadow: 0 0 9px 0 rgba(176, 172, 172, 0.5);
  background-color: #fefefe;
}

.webchat-preview-component {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.webchat-preview-close, .webchat-preview-title, .webchat-preview-info {
  color: inherit;
  font-size: 13px;
  font-family: 'Open Sans', Helvetica, Arial;
  line-height: 20px;
}

.webchat-preview-title {
  flex-grow: 1;
  text-align: center;
}

.webchat-preview-info {
  height: 20px;
  width: 20px;
}

.webchat-preview-header {
  width: 300px;
  height: 50px;
  padding: 15px;
  display: flex;
  object-fit: contain;
  border-top-left-radius: 10.8px;
  border-top-right-radius: 10.8px;
  background-image: radial-gradient(circle at 100% 12%, rgba(31, 36, 55, 0.72), #1f2437);
}

.webchat-preview-body {
  display: flex;
  flex-direction: column;
  height: 308px;
  list-style: none;
  padding: 15px;
  margin: 0px;
}

.webchat-preview-footer {
  display: flex;
  justify-content: space-between;
}

.webchat-preview-input {
  width: 250px;
  height: 30px;
  border-radius: 10px;
  border: solid 0.5px #595d6b;
  margin-right: 20px;
  text-align: right;
  padding: 7px;
}

.webchat-preview-params {
  margin-left: 10px;
  margin-right: 8px;
  width: 22px;
  height: 30px;
}

.webchat-preview-icon {
  width: 6em;
  height: 6em;
  margin-top: 1em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5em;
}

.webchat-preview-empty {
  width: 78px;
  height: 30px;
  display: flex;
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 10px;
}

.webchat-preview-empty-second {
  width: 200px;
  height: 70px;
}

.webchat-preview-bot-response, .webchat-preview-message {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: pre-line;
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
  font-family: 'Open Sans', Helvetica, Arial;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 10px;
  word-break: break-word;
}

.webchat-preview-bot-response {
  align-self: flex-end;
  color: black;
}

.BotConfig-container {
  width: 48em;
  background-color: white;
  border: solid 1px #e4e4e4;
  border-radius: 8px;
  padding: 2em;
}

.BotConfig-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BotConfig-title--h1 {
  padding-top: 10px;
}

.BotConfig-subtitle {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  color: #bababa;
}

.BotConfig-name {
  border: solid 1px #e4e4e4;
}
.BotConfig-avatar-container {
  margin-bottom: 0px;
}

.BotConfig-avatar-custom {
  border: dashed black 1px;
  width: 2em;
}

.BotConfig-avatar-custom-edit {
  width: 2em;
  box-shadow: 0 0 0 3px black;
  border: solid 2px white;
  position: relative;
}

.BotConfig-avatar-custom-edit-icon {
  opacity: 0;
  position: absolute;
  left: 10px;
  top: 10px;
  width: 2em;
  height: 2em;
  color: white;
}

.BotConfig-avatar-custom-edit:hover .BotConfig-avatar-custom-edit-icon {
  opacity: 1;
  -webkit-filter:drop-shadow(0px 0px 2px black);
          filter:drop-shadow(0px 0px 2px black)
}

.BotConfig-avatar-custom::after {
  position: absolute;
  left: 8px;
  top: 14px;
  content: '+';
  font-size: 4em;
}

.customize-color-box {
  min-width: 50px;
  min-height: 50px;
  border-radius: 50px;
  margin-right: 14px;
  background-clip: content-box;
  padding: 2px;
  cursor: pointer;
}

.ColourInput-color-box-input {
  visibility: hidden;
  vertical-align: top;
}

.BotConfig-container .customize-icon {
  margin-bottom: 0;
}

.BotConfig-color-custom {
  position: absolute;
  z-index: 2;
  top: -15.5em;
  left: -4em;
  padding: 1em;
  border-radius: 14px;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.23);
}

.BotConfig-custom-color-container {
  display: inherit;
  position: relative;
}

.BotConfig-color-custom-arrow-down {
  width: 25px;
  height: 25px;
  position: absolute;
  overflow: hidden;
  bottom: -25px;
  left: 68px;
  box-shadow: 0 0px 1px -3px rgba(0, 0, 0, 0.25);
}

.BotConfig-color-custom-arrow-down::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: -6px;
  left: 7px;
  box-shadow: -3px -3px 4px 3px rgba(0, 0, 0, 0.25);
}

.BotConfig-color-custom .chrome-picker {
  /* I know it's shitty to use important but it look to have no choice to overide the styles induce by the react-color component, 
  I'll change it with pleasure if some one got a solution for this */
  box-shadow: none !important;
  width: 10em !important;
}

.BotConfig-color-custom .chrome-picker>div:nth-child(2)>div:last-child>div:last-child {
  display: none;
}

.BotConfig-avatar-and-color-containers {
  display: flex;
  margin-top: 2em;
  justify-content: space-between;
}

.TextArea {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid transparent;
  line-height: 24px;
  overflow: auto;
  resize: none;
  outline: none;
  padding: 4px 12px;
  background: white;
  transition: all 0.3s;
}

.TextArea-border {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
}

.TextArea:focus-within {
  border: 1px solid #96c8da;
}

.TextArea-rounded {
  border-radius: 8px;
  border: solid 1px #ececec;
}

.TextArea::-webkit-input-placeholder {
  color: #B2B2B2;
}

.TextArea::placeholder {
  color: #B2B2B2;
}

.step-progress {
  margin: 4% 8% 4% 8%;
}

.multi-step {
  width: 40%;
}

.ui.progress {
  width: 40%;
  border-radius: 12px;
}

.ui.progress .bar {
  background: linear-gradient(to right, rgba(106, 55, 197, 1.00), rgba(109, 223, 155, 1.00));
  height: 12px;
  border-radius: 12px;
}

.ui.form .field>.selection.naming-dropdown {
  width: 100%;
}

.customize-icon-avatar {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  height: 20%;
  margin-top: 22px;
  margin-bottom: 68px;
}

.customize-subtitle {
  margin-bottom: 22px;
}

.customize-icon {
  min-width: 50px;
  min-height: 50px;
  margin-right: 14px;
  border-radius: 50px;
  position: relative;
  margin-bottom: 8.5%;
  cursor: pointer;
}

.customize-color {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  margin-top: 22px;
  margin-bottom: 55px;
}

.customize-icon-input {
  visibility: hidden;
  vertical-align: top;
}

.customize-icon-input:checked:before {
  content: '';
  visibility: visible;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: transparent;
  border: 3px solid;
  margin: -5px -8px -8px -5px;
}

.customize-icon-selected::before {
  position: absolute;
  margin-top: -5px;
}

.naming-dropdown {
  margin-bottom: 7%;
}

.naming-dropdown>div {
  color: black;
}

.progtrckr {
  list-style-type: none;
  padding: 0;
}

.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5em;
  cursor: pointer;
}

.progtrckr li span {
  padding: 0 1.5em;
}

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1em;
}

.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #CCCCCC;
}

.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

.progtrckr li:after {
  content: "\A0\A0";
}

.progtrckr li:before {
  position: relative;
  bottom: -4em;
  float: left;
  left: 50%;
}

.progtrckr-todo:before {
  content: "\39F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

.progtrckr-todo:hover:before {
  color: #ff4500;
}

.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #CCCCCC;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

.progtrckr-doing:hover:before {
  color: #ff4500;
}

.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

.progtrckr-done:hover:before {
  color: #333;
}

.bot-creation-form-title {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  color: #1A2B63;
  margin-bottom: 2%;
}

.ui.form .field {
  margin: 0;
}

.ui.form .field>.bot-creation-form-subtitle, .bot-creation-form-subtitle {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  color: #1A2B63;
  margin-bottom: 1.6%;
}

.ui.form .field>.DotLoader,
.bot-creation-useCases-error {
  padding: .78571429em 2.1em .78571429em 1em;
  margin-bottom: 7%;
}

.bot-creation-useCases-error {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: small;
  color: #888;
  margin-top: -7%;
}

.bot-creation-form-text {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #B2B1B1;
  text-align: left;
  margin-bottom: 4%;
}

.bot-creation-form-textarea {
  width: 100%;
  font-size: 14px;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  margin-bottom: 1.3%;
  padding: 14px;
  line-height: 20px;
}

.ui.form input:not([type]).bot-creation-form-input {
  width: 100%;
  margin-bottom: 7%;
}

.bot-creation-form-button-continue {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  background-color: #3B86FF;
  color: white;
  border: none;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.form-button-disabled {
  pointer-events: none;
  cursor: default;
  background-color: #f4f0f0;
  color: #cec7c7;
  border: 1px solid #BBBBBB;
}

.bot-creation-form-button-cancel, .bot-creation-form-button-back, .bot-creation-form-button-create, .bot-creation-form-button-continue:focus {
  outline-width: 0;
  cursor: pointer;
}

.bot-creation-form-button-create {
  margin-top: 2%;
  width: 40%;
}

.bot-creation-form-button-create:disabled {
  background-color: #f4f0f0;
  color: #cec7c7;
  border: 1px solid #BBBBBB;
}

.bot-creation-form-button-cancel {
  width: 40%;
  background-color: transparent;
  border: none;
  color: #3B86FF;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-top: 14px;
}
.bot-creation-form-button-back {
  position: absolute;
  margin-top: 46px;
  padding: 0;
  width: 34%;
}

.bot-creation-form-button-back-last {
  margin-top: 68px;
}

.congrats-container {
  border-radius: 8px;
  background-color: #ffffff;
  width: 58%;
  padding: 32px 0;
  text-align: center;
  margin: 5% auto auto;
  box-shadow: rgba(50, 50, 93, 0.1) 0 2px 4px;
}

.congrats-astro {
  width: 200px;
}

.congrats-text {
  text-align: center;
  width: 100%;
  font-size: 14px;
}

.congrats-li {
  text-align: left;
  margin: 30px;
  list-style-type: none;
  display: flex;
  color: #1a2b63;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.congrats-li-done {
  font-size: 30px;
}

.congrats-li-round {
  background: transparent;
  width: 35px;
  height: 35px;
  border: #1a2b63 solid 2px;
  border-radius: 35px;
}

.congrats-li-valid {
  color: #b2b1b1;
}

.congrats-li-round-valid {
  border-color:#b2b1b1;
}

.congrats-li-text {
  padding: 10px 10px 10px 16px;
  font-size: 16px;
}

.congrats-link, .congrats-link:hover {
  color: inherit;
  text-decoration: underline;
}

.bot-creation-form-content--preview {
  position: absolute;
  width: 300px;
  height: 320px;
  top: 17%;
  right: 10%;
}

.PublishButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 17px;
    background-color: #3a86ff;
    font-size: 14px;
    font-family: 'Open Sans', Helvetica, Arial;
    font-weight: bold;
    color: white;
    padding: 7px 35px;
}

.publish-button-uptodate {
    background-color: rgba(71, 51, 143, 0.25);
}

.publish-button-inprogress{
    background-color: rgba(71, 51, 143, 0.75);
    padding: 7px 15px 7px 10px;
}

.ui.inline.loader.PublishButton-loader {
    margin-right: 12px;
}

.remote-button__dotloader {
    font-family: Rubik;
    font-size: 1em;
    color: var(--gray-700);
    font-weight: normal
}

.remote-button__dotloader > .lds-ellipsis div {
    background: var(--gray-700);
}

.ErrorPublish {
    display: flex;
    align-items: center;
    color: var(--danger-500);
}

.ErrorPublish-text {
    font-family: Rubik;
    font-size: 1em;
    font-weight: 500;
    line-height: 0;
    margin: 0;
    margin-right: var(--spacing-xs);
}

.publish-button-container {
    display: flex;
    align-items: center;
}

button:hover + .publish-button-training--hover {
    display: none
}

@media(min-width: 1150px) {
    button:hover + .publish-button-training--hover {
        display: block;
        width: auto;
        opacity: 1;
        margin-right: 1em;
        -webkit-animation-name: publish;
                animation-name: publish;
        -webkit-animation-duration: 0.3s;
                animation-duration: 0.3s;
    }
}

.publish-button-container button + .publish-button__training-infos {
    z-index: -1;
    font-family: Rubik;
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.83;
    color: var(--gray-600);
    position: absolute;
    width: auto;
    padding-right: 1em;
    margin: 0;
    opacity: 0;
    -webkit-transform: translateX(-80%);
            transform: translateX(-80%);
    transition: opacity 0.1s ease-in-out, -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, opacity 0.1s ease-in-out;
    transition: transform 0.2s ease-in-out, opacity 0.1s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.publish-button-container button:hover + .publish-button__training-infos {
    opacity: 1;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.publish-button-container button:disabled {
    cursor: unset;
}
.Button {
  display: flex;
  padding: 8px 18px;
  cursor: pointer;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  border: none;
  outline: 0;
  justify-content: center;
  opacity: 0.75;
}
.Button:hover {
  opacity: 1;
}

.Button-default {
  border-radius: 8px;
}

.Button-rounded {
  border-radius: 16px;
}

.Button-disabled {
  opacity: 0.6;
  cursor: default;
}
.Button-disabled:hover {
  opacity: 0.6;
}

.Button-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.Button:focus {
  outline: 0;
}

.primary.SaveButton {
  width: 100px;
  min-height: 2.5em;
  border-radius: 8px;
  font-weight: bold;
}

.SaveButton-icon {
  margin-right: 10px;
}

.QnABackButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}

.QnABackButton-content {
    color: #434b65;
    text-align: center;
    height: 30px;
    cursor: pointer;
}

.QnABackButton-label {
    border-radius: 6px;
    color: #434b65;
    padding: 4px 16px;
    font-size: 18px;
    font-weight: bold;
}


.WebchatConfig {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.WebchatConfig .BotConfig-container {
  margin-bottom: 2em;
}

.WebchatConfig-preview {
  position: absolute; /*Container makes me do that to have the whole height with the white background*/
  right: 0;
  padding: 3em;
  background-color: white;
  height: 100%;
}

.WebchatConfig--container {
  width: 48em;
  margin: 2em;
}

.WebchatConfig--title {
  margin-bottom: 0;
}

.WebchatConfig-script--container {
  background-color: white;
  border: solid 1px #e4e4e4;
  border-radius: 8px;
  padding: 2em;
}

.WebchatConfig-script-title--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.WebchatConfig-script--text {
  width: 60%;
  color: #bababa;
  margin-bottom: 2em;
}

.WebchatConfig-script-title--h1 {
  margin-bottom: 0;
}

.WebchatConfig-script-title--button {
  color: #3a86ff;
  background-color: white;
  border: solid 1px #3a86ff;
  border-radius: 5px;
  padding: 0.4em;
}

.WebchatConfig-script-title--button:hover {
  cursor: pointer;
}

.WebchatConfig-script-title-button--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.WebchatConfig-button-text {
  font-size: 8px;
  font-weight: bolder;
  margin: 0.3em 0.5em;
}

.WebchatConfig-script {
  padding: 2em 1.5em;
  color: white;
  background-color: #202537;
  font-size: 12px;
  font-family: 'Monaco';
}

.WebchatConfig-script::selection {
  background-color: white;
}

.WebchatConfig--title-container {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  justify-content: space-between;
}

.WebchatConfig--title-container-left-content {
  display: flex;
  align-items: center;
}

.WebchatConfig--title-container-left-content > .ui.toggle.checkbox {
  margin-left: 1rem;
}
.TextInput {
  border: none;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  outline: none;
  padding: 0 12px;
  background: none;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

.TextInput-container {
  height: 2.5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-evenly;
  border: 1px solid transparent;
  transition: all 0.3s;
  background: white;
}

.TextInput-decorator {
  padding-right: 12px;
  line-height: 1em;
}

.TextInput-decorator:hover {
  cursor: pointer;
}

.TextInput:focus + div.TextInput-decorator {
  color: #6ba4ff
}

.TextInput-border {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
}

.TextInput-border:focus-within {
  border: 1px solid #6ba4ff;
}

.trainingQuestion-label>.TextInput-container>.TextInput {
  padding: 0;
}

.TextInput-rounded {
  border-radius: 8px;
  border: solid 1px #ececec;
}

.TextInput-rounded:focus-within {
  border: solid 1px #6ba4ff;
}

.TextInput::-webkit-input-placeholder {
  color: #B2B2B2;
}

.TextInput::placeholder {
  color: #B2B2B2;
}

.script--container {
  background-color: white;
  border: solid 1px #e4e4e4;
  border-radius: 8px;
  padding: 2em;
}

.script-title--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.script--text{
  width: 60%;
  color: #bababa;
  margin-bottom: 2em;
}

.script-title--h1 {
  margin-bottom: 0;
}

.script-title--button {
  color: #3a86ff;
  background-color: white;
  border: solid 1px #3a86ff;
  border-radius: 5px;
  padding: 0.4em;
}

.script-title--button:hover {
  cursor: pointer;
}

.script-title-button--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Script {
  padding: 2em 1.5em;
  color: white;
  background-color: #202537;
  font-size: 12px;
  font-family: 'Monaco';
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.Script::selection {
  background-color: white;
}

.button-text {
  font-size: 8px;
  font-weight: bolder;
  margin: 0.3em 0.5em;
}

.EmptyBotPage_topbar__1v-dH {
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  background-color: #fff;
}

.EmptyBotPage_topbar__logo__1IPxF {
  width: 5.72em;
  margin-left: 4.29em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.Option {
  padding: .67857143em 2.1em .67857143em 1em;
  display: flex;
  background-color: #FFF;
  cursor: pointer;
  overflow: hidden;
}

.Option-striped {
  background-color: #f8f8f8;
}

.Option-text {
  text-overflow: ellipsis;
}

.Option-icon {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: block;
  width: 3.5em;
  margin-right: 0.5em;
}

.Option:hover {
    background-color: #F0F0F0;
}

.Select {
    position: relative;
    min-width: 250px;
}

.Select-control {
    display: flex;
    border-radius: 4px;
    border: 1px solid rgba(34, 36, 38, .15);
    padding: .67857143em 2.1em .67857143em 1em;
    min-height: 36px;
    align-items: center;
}

.Select-icon {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: block;
  width: 3.5em;
  margin-right: 0.5em;
}

.Select-control--selectedText {
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.Select-control--selectedText---text {
    display: flex;
    text-overflow: ellipsis;
}

.Select-control--faCaret, .Select-control--cross {
    position: absolute;
    right: 12px;
    opacity: 0.7;
}

.Select-control--cross {
    font-size: 1.5em;
}

.Select-control--open {
    border-color: #96c8da;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.Select-control--placeholder {
    color: rgba(191,191,191,.87);
}

.Select-control:hover {
    border-color: rgba(100,100,100,.6);
}

.Select-control--open:hover {
    border-color: #96c8da;
}

.Select-options {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    border: 1px solid #96c8da;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    max-height: 180px;
    overflow-y: auto;
}

.Select-wrapper {
    position: relative;
}

.Select-textInput {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    height: 32px;
    border-top: 1px solid #F2F2F2;
    border-left: 1px solid #96c8da;
    border-right: 1px solid #96c8da;
}

.Select-textInput:focus {
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    border-left: 1px solid #96c8da;
    border-right: 1px solid #96c8da;
}

.Notifications {
  position: fixed;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
}

.Notifications .notification .notification-wrapper {
  transition-delay: 250ms;
  padding: 1em 2.5em 1em 2.5em;
  position: relative;
  left: -50%;
  margin: 1px;
  box-shadow: 1px 7px 8px -5px;
  display: flex;
  justify-content: space-between;
  background-color: #192B63;
  border-radius: 5px;
}

.Notifications .notification .notification-wrapper .notification-message {
  color: #ffffff;
}

.Notifications .notification .notification-wrapper button {
  background-color: #192B63;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  padding-left: 34px;
  outline:none;
}

.Notifications .notification .notification-wrapper button.button--cancel {
  color: #EB5656
}

.FormLabel-label {
  display: block;
  margin-bottom: 4px;
  font-weight: 800;
  color: #565D74;
}

.FormLabel-required {
  color: #F76A72;
  margin-left: 5px;
  font-weight: 800;
}

.FormError {
  color: #F76A72
}

.FormInput-input {
  font-family: 'Open Sans', Helvetica, Arial;
  margin: 0;
  outline: 0;
  padding: .7em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87);
  border-radius: 8px;
  box-shadow: 0 0 0 0 transparent inset;
  transition: all 0.3s;
  width: 100%;
}

.FormInput-input--error {
  border: 1px solid #F76A72;
}

.FormInput-formError {
  margin-left: 12px;
}
.RemoteRegister-success-container {
  text-align: center;
  margin-top: 4em;
}

.RemoteRegister-tada-title,
.RemoteRegister-tada-text {
  font-size: 14px;
  font-family: 'Open Sans', Helvetica, Arial;
  color: #4c5570;
}

.RemoteRegister-tada-title {
  font-size: 18px;
  font-weight: bold;
}

.Register-submitButton {
  background-color: #3B86FF;
  text-transform: uppercase;
  color: white;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  padding: 0.5em;
  font-weight: bold;
  font-family: 'Open Sans', Helvetica, Arial;
  font-size: 14px;
  box-shadow: 0 10px 20px 0 rgba(196, 196, 196, 0.5);
  cursor: pointer;
}

.Register-submitButton:disabled {
  opacity: .25;
  cursor: auto;
}

.Register-form-container>.FormInput>.FormLabel-label {
  font-family: 'Open Sans', Helvetica, Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  color: #1A2B63;
  margin-bottom: 1em;
}

.AuthTab-container>div>div.ui.segment.active.tab>form>div:nth-child(1) {
  margin-top: 2em;
}

.AuthTab-container>div>div.ui.segment.active.tab>form>div {
  margin-bottom: 2em;
}

.FormInput>.FormError.FormInput-formError {
  margin-left: 0;
}
.AuthTab-container {
  margin-top: 8em;
}

.AuthTab-title {
  color: #434b65;
  font-size: 20px;
  font-family: 'Open Sans', Helvetica, Arial;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.AuthTab-container>div {
  width: 20em;
}

.AuthTab-container>div>div.ui.segment.active.tab {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
}

.AuthTab-container>div>div.ui.pointing.secondary.menu>a.item {
  color: lightgrey;
  padding: 2em 3.3em;
}

.AuthTab-container>div>div.ui.pointing.secondary.menu>a.active.item {
  border-color: #76aeff;
  color: #434b65;
  width: 100%;
  text-align: center;
  display: block;
}

.AuthPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 112%;
  background-color: #f9f9f9;
}

.AuthPage-authForm {
  margin-top: 120px;
  width: 350px;  
}

.AuthForm {
  display: flex;
  flex-direction: column;
}

.AuthForm-help {
  text-align: center;
}

.AuthForm-submitButton {
  margin-top: 20px;
  text-align: center;
  border-radius: 8px;
  background-color: #3B86FF;
  padding: 0.5em;
  font-weight: bold;
  box-shadow: 0 10px 20px 0 rgba(196, 196, 196, 0.5);
}

.AuthForm-button-text {
  width: 100%;
}

.AuthForm-icon {
  position: relative;
  left: 92%;
  bottom: 25px;
  color: #D9DBE0;
}

.AuthForm-icon-active {
  color: #3B86FF;
}


.AuthForm>.FormInput>.FormLabel-label {
  color: #1A2B63;
  margin-bottom: 1em;
}

#start-button:focus {
  outline: none;
}

.loadingContainer {
  margin-top: 50px;
  min-height: 100%;
  background-color: white;
}

.headerDiv {
  background-color: #f2f3f8;
  height: 100%;
}

.mainContainer {
  margin-top: 70px;
  height: 100%;
  background-color: #f9f9f9;
}

.App-content {
  position: relative;
  left: 214px;
  width: calc(100% - 214px);
  height: 100%;
  overflow-y: auto;
  font-family: 'Open Sans', Helvetica, Arial;
  overflow-x: hidden;
}

.App-content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

.App-navbarMenu {
  width: 240px;
}

.mainContent {
  min-height: 100%;
}

/* uiLibrary table property not the same from workspace to bot */
tbody > tr > td >span {
  box-sizing: content-box;
}

/*@import 'https://code.highcharts.com/css/highcharts.css';*/

.Question .Question-icon-fire,
.Question .Question-icon-shuffle {
  padding: 0;
  margin: 0;
  border: none;
  margin-right: 0.5em;
  height: 1.5em;
}

.ui.dropdown .menu > .item {
  padding-left: 0.25em;
  display: flex;
  align-content: center;
  transition: color 0.25s ease;
}

.ui.dropdown .menu > .item.text-muted {
  color: #d2d5db;
  transition: color 0.25s ease;
}

.ui.dropdown .menu > .item svg {
  margin-right: 0.5em;
}

.AddSynonym-TextInput {
    width: 50%;
    max-width: 400px;
    margin-bottom: 14px;
}

.AddSynonym-AddIcon {
    color: rgba(34,36,38,.15);
}

.AddSynonym-TextInput > .TextInput-container:focus-within .AddSynonym-AddIcon {
    color: #96c8da;
}
.Synonym-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.Synonym-list > li {
    list-style: none;
}

.Synonym-list > *:not(:last-child) {
    margin-bottom: 2px;
}

.Synonym-row {
    background-color: transparent;
    padding: 10px;
    display: flex;
    flex-direction: row;
    padding: 0;
}

.Synonym-row > li:first-child {
    flex-grow: 1;
    padding-left: 0;
}

.Synonym-row > li {
    list-style: none;
    padding-left: 15px;
    align-self: center;
}


.Synonym-row .TextInput-container.TextInput-disabled {
    background: none;
}

.Synonym-row--icon {
    color: #434b65;
    margin-left: auto;
    cursor: pointer;
}

.ui.active.inline.loader.Synonym-row--loading {
    margin-left: auto;
    right: 0;
}

.KeywordCounter {
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1em;
    height: 1em;
    text-align: center;
}

.KeywordCounter > span {
    display: inline-block;
    align-self: center;
    line-height: normal;
    vertical-align: middle;
}

.KeywordCounter-flag {
    font-size: 1.5em;
    margin-right: 15px;
}

.KeywordCounter-label {
    background: #434b65;
    color: white;
    padding: 2px 10px;
    border-radius: 10px;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 0.9em
}

.KeywordBreadcrumb {
    margin-left: 30px;
    margin-top: 40px;
    color: #434B65;
    font-size: 1.2em;
    line-height: 1.5em;
    font-family: 'Open Sans', Helvetica, Arial;
    font-weight: 100;
    display: flex;
    align-items: center;
}

.KeywordBreadcrumb-path {
    display: flex;
    align-items: center;
}

.KeywordBreadcrumb-title {
    font-weight: bold;
    font-size: 18px;
}

.KeywordBreadcrumb-link {
    color: rgba(67, 75, 101, 1);
    font-weight: 900;
    display: flex;
    margin-right: 15px;
}

.KeywordBreadcrumb > *:not(:first-child) {
    margin-left: 10px;
    display: flex;
}

.ui.tabular.menu > .item.Keywords-tabItem {
    font-size: 1.5em;
    padding: 0 10px;
}
.Keyword-content {
    display: flex;
    padding: 30px;
}

.Keyword-content--tab {
    flex-grow: 5;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
    padding: 15px 25px;
}

.Keyword-content--tab>.ui.tabular.menu,
.Keyword-content--tab>.ui.bottom.attached.segment.active.tab,
.Keyword-content--tab>.ui.attached.tabular.menu>.active.item.Keywords-tabItem {
    border: none;
    font-size: 14px !important;
}
.CreateKeyword-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.CreateKeyword-actions>.ui.button {
    margin-left: 10px;
}

.create-keyword-form-label {
    color: #434b65;
    font-family: 'Open Sans', Helvetica, Arial;
}
.EmptyStateContent-container {
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
}

.EmptyStateContent-backpage {
  height: 100%;
}

.emptyElement-container {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #ececec;
  padding: 2em;
  justify-content: space-between;
}

.emptyContent {
  content: "";
  height: 1em;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.emptyElement-first {
  width: 40%;
}
.emptyElement-second {
  align-items: end;
  width: 5%;
}

.emptyCard-container {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  min-width: 350px;
  border-radius: 10px;
  box-shadow: 0 0 77px 0 #d9d9d9;
  padding: 3em;
  text-align: center;
}

.emptyCard-container > h1 {
  margin-top: 0.5em;
  font-size: 2em;
}

.emptyCard-text {
  color: #c2c2c2;
  margin: 0;
}

.emptyCard-icon {
  width: 7em;
}
.emptyCard-children-container {
  display: inline-block;
  margin-top: 2em;
}

.Keywords {
    margin: 30px 30px 120px;
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
}

.Keywords-actionBar {
    display: flex;
    justify-content: flex-start;
    margin: 20px 30px;
}

.Keyword-label {
    flex-grow: 2;
}

.Keyword-label-name {
    border-radius: 15px;
    color: #434b65;
    cursor: pointer;
    opacity: 1;
}

.Keyword-label-name:hover {
    color: #434b65;
}

.Keywords-modal-title {
    color: #434b65;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

.Keywords-button.ui.small.inverted.button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    padding: 10px;
    background-color: #3a86ff;
    font-weight: bold;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    border-radius: 8px;
    color: white;
    opacity: 0.75;
}

.Keywords-button-icon {
    margin-right: 5px;
}

.Keywords-button.ui.small.inverted.button:hover {
    opacity: 1;
}

.Keywords-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background-color: white;
    border-radius: 8px;
}

.Keywords-list > li {
    list-style: none;
    border-bottom: solid #dadada 1px;
}

.Keywords-list > li:last-child {
    border: none;
}

.Keywords-row {
    padding: 14px;
    color: #4a5771;
    display: flex;
    flex-direction: row;
}

.Keywords-row:hover {
    opacity: 1;
    background-color: #f9f9f9;
}

.Keywords-row > li {
    list-style: none;
    align-self: center;
    padding: 0 10px;
}

.Keywords-row > li:first-child {
    flex-grow: 1
}

.Keyword-row-container {
    display: flex;
    align-items: center;
    position: relative;
}

.Keyword-row-icons-container {
    display: flex;
}

.Keyword-row-link-container {
    flex-grow: 1;
}

.Keyword-icons-trash {
    margin-right: 1em;
    cursor: pointer;
}

.Keyword-linkedElement--title {
    margin-bottom: 0;
}

.Keyword-linkedElement--list {
    margin-bottom: 1em;
}

.Keyword-row-icons-container {
    position: absolute;
    right: 1em;
}

.Keyword-row-icons-checkbox-container {
    display: flex;
    margin-right: 1em;
}

.Keyword-checkbox {
    font-size: 14px !important;
}

.TextInputMaterial-loader {
  align-self: flex-end;
  margin-left: 16px;
}

.TextInputMaterial-wrapper {
  display: flex;
  height: 40px;
  flex: 9 1;
  align-items: center;
  transition: all 0.4s;
  padding: 0 14px;
  background-color: white;
  border: solid 1px #ececec;
  border-radius: 8px;
}

.TextInputMaterial-wrapper---invalid {
  border: solid 1px red;
}

.TextInputMaterial-wrapper--input {
  flex-grow: 1;
  border: none;
  transition: all 0.3s;
  outline: none;
  background: none;
  color: #434B65;
  caret-color: #434B65;
}

.TextInputMaterial-wrapper--flyingPlaceholder {
  flex-grow: 1;
  color: #D2D2D2;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
          user-select: none;
  cursor: text;
}

.TextInputMaterial-wrapper--flyingPlaceholder---default {
  color: #d8d8d8;
}

.TextInputMaterial-wrapper--flyingPlaceholder---up {
  display: none;
}

.TextInputMaterial-wrapper--flyingPlaceholder---up----focuslost {
  color: #B2B2B2;
}

.TextInputMaterial-wrapper--hidden {
  display: none;
}

.TextInputMaterial-wrapper--alert-icon {
  fill: red;
  stroke: white;
  height: 1.4em;
  width: 1.4em;
}

.trainingQuestion {
  display: flex;
  flex-direction: row;
  padding: 0px 10px 10px 58px;
}

.trainingQuestion-label {
  display: flex;
  flex-basis: 55%;
  align-items: center;
}

.trainingQuestion-label:hover {
  background-color: #fbfbfb;
  cursor: text;
}

.trainingQuestion-label:focus {
  background-color: #f1d09c21;
  cursor: text;
}

.trainingQuestion-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.trainingQuestion-button {
  margin-left: 10px;
}

.trainingQuestion-button:hover {
  cursor: pointer;
}

.trainingQuestion-label--input {
  flex-grow: 1;
}

.trainingQuestion-label--alert-icon {
  fill: red;
  stroke: white;
  height: 1.5em;
  width: 1.5em;
}

.trainingQuestion-label--alert-icon--hidden {
  display: none;
}

input:focus {
    outline: none;
}

.trainingQuestions {
    background: #FFF;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #D2D2D2;
}

.trainingQuestion--input {
    max-width: 50%;
    margin-top: 12px;
    margin-left: 4%;
    margin-bottom: 30px;
}

.trainingQuestion--title {
    margin-left: 4%;
    font-size: 14px;
    margin-bottom: 0px;
}

.trainingQuestion--p {
  color: #777777;
  font-size: 13px;
  background-color: #f4f6fc;
  border: 1px solid #e0e7ff;
  width: 250px;
  border-radius: 21px;
  padding: 10px 0px 0px 0px;
  text-align: center;
  height: 42px;
  margin: 0px 55% 20px 15%;
}

.trainingQuestion--parent-block{
  max-width: 50%;
  margin-left: 4%;
  height: 100px;
}

.trainingQuestion--pretrained-bg{
  height: 100px;
}

.trainingQuestion--pretrained-bg .trainingQuestion--pretrained-bg-bubble{
  width: 100%;
  height: 17px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.trainingQuestion--pretrained-bg .trainingQuestion--pretrained-bg-bubble-dark{
  background-color: #f6f6f6;
}
.trainingQuestion--pretrained-bg .trainingQuestion--pretrained-bg-bubble-normal{
  opacity: 0.65;
  background-color: #f6f6f6;
}
.trainingQuestion--pretrained-bg .trainingQuestion--pretrained-bg-bubble-clear{
  opacity: 0.35;
  background-color: #f6f6f6;
}

.trainingQuestion--pretrained-content{
  position: relative;
  top: -100px;
  text-align: center;
  color: #777777;
  font-size: 13px;
  background-color: #f4f6fc;
  border: 1px solid #e0e7ff;
  width: 250px;
  border-radius: 21px;
  padding: 10px 0px 0px 0px;
  text-align: center;
  height: 42px;
  margin: 20px auto;
}

.LinkAction {
  cursor: pointer;
}

.Question {
  position: relative;
  width: 100%;
  padding: 14px 45px 14px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #4a5771;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.1em;
}

.Question-border {
  border-top: solid 1px #f9f9f9;
}

.Question-border:hover {
  background-color: #f9f9f9;
}

.Question-border:hover .Question-label {
  background-color: #f9f9f9;
}

.Question-label-wrapper {
  width: 100%;
}

.Question-label-input-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-right: 1em;
}

.Question-label {
  flex: 1 0;
  transition-duration: 0s;
  height: 2em;
}

.Question-archived {
  opacity: 0.5;
}

.Question-label .TextInput {
  height: 1.5em;
  padding-right: 0;
  margin-left: 0;
}

.Question-label--input {
  padding: 0;
  margin: 0;
  border: none;
  height: 1.5em;
}
.Question-label--input > .TextInput {
  padding: 0;
  margin: 0;
}

.Question-label--hidden {
  display: none;
}

.Question-button--label {
  display: flex;
  transition-duration: 300ms;
}

.Question-button--label-icon {
  cursor: pointer;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  background-color: inherit;
  height: 25px;
}

.Question-link {
  color: #4a5771;
  flex-grow: 1;
  display: flex;
}

.Question.Question-border:hover .Question-action_button {
  opacity: 0.5;
}

.Question.Question-border:hover .Question-action_button:hover {
  opacity: 1;
}

.Question-action_description {
  font-size: 0.8em;
  font-weight: bold;
  margin-right: 1em;
}

.Question-action_button {
  color: #4a5771;
  cursor: pointer;
  opacity: 0;
  margin: 0 0.5em;
}

.Question-button {
  margin-right: 10px;
  color: #4a5771;
}

.ui.transition.visible.Question-popup {
  font-size: 0.85em;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  color: white;
  background-color: #434b65;
  border: solid 1px #434b65;
  border-radius: 6px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.5);
}

.ui.transition.visible.Question-popup::before {
  box-shadow: none;
  background-color: #434b65;
}

.ui.label.Question-info-label {
  border-color: rgba(0, 0, 0, 0.075);
}

.Question-buttons {
  display: flex;
  justify-content: flex-end;
  font-size: 1.3em;
}

.Question-buttons .ui.dropdown > .text {
  display: none;
}

.Question-buttons .ui.dropdown .menu > .item {
  max-width: 40em;
  overflow: overlay;
}

.Question-button--input {
  display: none;
}

.Question-button--input:checked + label .Question-button--label {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.Question-label-alert-icon {
  fill: red;
  stroke: white;
  height: 1.5em;
  width: 1.5em;
}

.Question-label-alert-icon--hidden {
  display: none;
}

.Question-linkedElement--title {
  margin-bottom: 0;
}

.Question-linkedElement--list {
  margin-bottom: 1em;
}

.Question-icons-trash {
  cursor: pointer;
}

.DateRangeComponent .DateInput {
    background: #3B86FF;
}

.DateRangeComponent .DateInput_input {
    background-color: #3B86FF;
    color: white;
    padding: 5px 0px;
    vertical-align: middle;
}

.DateRangeComponent .DateInput_input__focused_2 {
    color: white;
}

.DateRangeComponent .DateRangePickerInput {
    width: 310px;
    background-color: #3B86FF;
    height: 2.5em;
    display: flex;
    align-items: center;
}

.DateRangeComponent .DateRangePickerInput_arrow_svg {
    fill: white;
    width: 50px;
    margin-right: 20px;
}

.DateRangeComponent .DateInput_displayText__focused {
    background-color: #3B86FF;
}

.DateRangeComponent .CalendarDay__selected_span {
    background-color: #C6DAFC;
    border-color: #C6DAFC;
    color: #3B86FF;
}

.DateRangeComponent .CalendarDay__selected,
.DateRangeComponent .CalendarDay__selected:active,
.DateRangeComponent .CalendarDay__selected:hover,
.DateRangeComponent .CalendarDay__selected_span:active,
.DateRangeComponent .CalendarDay__selected_span:hover,
.DateRangeComponent .CalendarDay:hover {
    background-color: #3B86FF;
    border-color: #3B86FF;
    color: white;
}

.DateRangeComponent .DateRangePickerInput_calendarIcon_svg,
.DateRangeComponent .DateRangePickerInput_clearDates_svg {
    fill: white;
    margin-bottom: 2px;
}

.DateRangeComponent .DateRangePickerInput_clearDates_default:focus,
.DateRangeComponent .DateRangePickerInput_clearDates_default:hover {
    background-color: transparent;
}


.PreselectButtons {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Dashboard-container {
  max-width: 1080px;
  margin: auto;
  padding: 2em;
}

.Dashboard-container-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.Dashboard-container-header--title {
  margin: 0;
}

.Dashboard-mini-card-container {
  display: flex;
  margin-top: 2.3em;
}

.Dashboard-container-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.Dashboard-container-header--title {
  margin: 0;
}

.Dashboard-card,
.Dashboard-mini-card,
.Dashboard-cardline {
  border-radius: 8px;
  box-shadow: 0 0 5px 0 #b5b5b5;
  background-color: white;
}

.Dashboard-card {
  padding: 30px;
}

.Dashboard-mini-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;
  margin-right: 1.5em;
  padding: 1em;
  line-height: 1;
}

.tolk-pie-chart-no-data {
  font-size: 2.56em;
  text-align: right;
  font-weight: bold;
}

.Dashboard-mini-card:last-child {
  margin-right: 0;
}

.Dashboard-cardline {
  min-width: 510px;
  max-width: 1050px;
  padding: 30px;
}

.Dashboard-mini-card--title,
.Dashboard-card--title {
  margin-bottom: 20px;
  padding: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434b65;
}

.Dashboard-card--title {
  font-size: 1.3em;
}

.Dashboard-mini-card--title {
  font-size: 1em;
}

.Dashboard-card--Percent,
.Dashboard-mini-card--Percent {
  color: #3289F6;
  font-weight: bold;
}

.Dashboard-card--Percent {
  font-size: 3em;
}

.Dashboard-mini-card--Percent {
  font-size: 2.56em;
  text-align: right;
}

.Dashboard-card--chart {
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
  margin-bottom: 1em;
}

.Dashboard-layout-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2.3em;
}

.Dashboard-layout-item {
  width: 50%;
  margin-right: 1.5em;
  flex-grow: 1;
}

.Dashboard-layout-item:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1024px) {
  .Dashboard-mini-card-container {
    flex-wrap: wrap;
  }

  .Dashboard-mini-card {
    width: 48%;
    margin-bottom: 1.5em;
    margin-right: 4%;
  }

  .Dashboard-mini-card:last-child {
    margin-bottom: 0;
  }

  .Dashboard-mini-card:nth-child(even) {
    margin-right: 0;
  }

  .Dashboard-layout-container {
    flex-direction: column;
  }

  .Dashboard-layout-item {
    width: 100%;
    margin-right: 0;
  }

  .Dashboard-layout-item {
    margin-bottom: 2.3em;
  }

  .Dashboard-layout-item:last-child {
    margin-bottom: 0;
  }
}

.WebchatOverview-tbody:before {
    content: "-";
    display: block;
    line-height: 1em;
    color: transparent;
}

.WebchatOverview-tr {
  height: 5em;
  border-bottom: solid 1px #e9e9e9;
}

.WebchatOverview-tr:nth-last-child(-n+1) {
  border: none;
}

.WebchatOverview-tr-higthlight {
  border: none;
}

.WebchatOverview-container {
  margin: auto;
  padding: 2em;
  font-family: "Open sans", sans-serif;
  color: #434b65;
}

.WebchatOverview-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.3em;
  
}

.WebchatOverview-header-container-title {
  font-size: 1.5em;
  margin-bottom: 0;
  font-weight: bold;
  color: #434b65;
}

.WebchatOverview-content-container-title {
  padding: 1em;
  margin-bottom: 0em;
  color: #434b65;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2em;
}

.WebchatOverview-table {
  width: 100%;
  letter-spacing: 1px;
  text-align: left;
  background-color: #fff;
  border-radius: 3px;
  border-collapse: collapse;
  border-spacing: 2em;
  border: none;
  min-height: 150px;
  font-family: "Open Sans",Helvetica,Arial,sans-serif;
}

.WebchatOverview-table th {
  overflow-wrap: break-word;
  max-width: 26em;
  padding-left: 2em;
  padding-right: 2em;
}

.WebchatOverview-table-th-link {
  color: #434b65;
}

.WebchatOverview-tr--title {
  margin-bottom: 1.2em;
}

.WebchatOverview-table-th,
.WebchatOverview-table-td {
  text-align: center;
}

.WebchatOverview-url {
  text-align: left;
}
.WebchatOverview-url-text {
  margin-left: 1em;
}

.WebchatOverview-double-container {
  display: inline-flex;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 0.5em 3em;
}
.WebchatOverview-double-separator {
  height: 37px;
  border: solid 1px #979797;
  height: 3.375em;
  opacity: 0.36;
  width: 0.01em;
  margin: 0px 10px 0px 10px;
}

.WebchatOverview-overall-display {
  background: white;
  border-radius: 10px;
  padding: 1.2em 4em;
}

.WebchatOverview-content-container {
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
  background-color: #ffffff;
}

.WebchatOverview-tr-higthlight {
  background-color: rgba(181, 181, 181, 0.1) ;
}

.WebchatOverview-title-image-container {
  position: relative;
}

.WebchatOverview-preonboarding-img {
  margin-bottom: 2em;
  width: 3em;
  height: 3em;
}

.WebchatOverview-chatwindow-img {
  margin-bottom: 2em;
  width: 3em;
  height: 3em;
}

.WebchatOverview-th-col-title {
  margin-top: 5em !important; /* I don't like to use this !important but I have no choice here, semantic ui force some css, but actually it's on a css class so i don't think there side effect here */
}

.WebchatOverview-table .WebchatOverview-tr--title p {
  color: #4c5570;
  opacity: 0.5;
  font-size: 1em;
  font-weight: bold;
}

.WebchatOverview-values-container {
  display: inline-flex;
  align-items: center;
  background: white;
  padding: 0.5em 3em;
}

.WebchatOverview-double-container div {
  padding-right: 1em;
  border-right: 1px solid #97979785;
}
.WebchatOverview-double-container div:last-child {
  border-right: 0;
  padding-left: 1em;
  padding-right: 0;
}

.WebchatOverview-values-container div {
  padding-right: 2em;
  border-right: 1px solid #97979785;
}

.WebchatOverview-values-container div:last-child {
  border-right: 0;
  padding-left: 2em;
  padding-right: 0;
}

.WebchatOverview-double-container p {
  opacity: 0.5;
}
.RoungProgressBar-text {
  font-family: 'Open Sans', Helvetica, Arial;
  font-size: 12px;
  font-weight: 200;
}

.ui.menu.MonitorTabs-menu {
  font-family: 'Open Sans', Helvetica, Arial;
  min-height: 70px;
}

.MonitorTabs {
  background-color: #ffffff;
  height: 5.5em;
  display: flex;
}

.linkButtons {
  display: flex;
  justify-content: flex-start;
  width: 80%;
}

.ui.menu.MonitorTabs-menu {
  border: none;
  box-shadow: none;
}

.ui.menu.MonitorTabs-menu--item {
  margin-right: 2em;
  color: #8e9296;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  background-color: unset;
}

.MonitorTabs-menu--item {
  margin-left: 3em;
}

.ui.menu .MonitorTabs-menu--item:before {
  background: none;
}

.ui.menu .MonitorTabs-menu--item:hover {
  cursor: pointer;
  background-color: unset;
}

.ui.menu .MonitorTabs-menu--item.active {
  font-weight: 600;
  color: #000000b5!important;
  border-bottom: 3px solid #0089ff;
  border-radius: 0%;
  margin-top: 3px;
  background-color: unset;
}

.otherButtons {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  padding-right: 8%;
}

.MonitorTabs-menu-validate {
  border-radius: 8px;
  margin: 17px 0px 17px 24px;
  height: 2.9em;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  min-width: 11em;
  border: none;
}

.activated-button {
  background-color: #3a86ff;
  color: white;
}

.activated-button:hover {
  cursor: pointer;
}

.deactivated-button {
  background-color: #3a86ffa6;
  color: #e8e8e8d1;
}

.MonitorTabs-RoundBar {
  height: 100%;
}

.MonitorTabs-Roundbar--label {
  font-weight: bold;
  margin-left: 1em;
  align-self: center;
  color: #616b87;
}

.unmatched-header-index,
.unmatched-header-text,
.unmatched-header-matching,
.unmatched-question-index,
.unmatched-question-text {
  margin: 0;
}

.unmatched-component {
  padding: 3% 8% 3% 3%;
  background-color: #f9f8f8;
}

.unmatched-header,
.unmatched-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2.6%;
  padding-right: 2%;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin-bottom: 1%;
}

.unmatched-header {
  opacity: 0.5;
  height: 20px;
}

.unmatched-question {
  background-color: white;
  border: solid 1px #ececec;
  border-radius: 10px;
  height: 80px;
}

.unmatched-header-index,
.unmatched-question-index {
  display: flex;
  justify-content: center;
  width: 5%;
}

.unmatched-question:hover > .unmatched-question-index {
  display: none;
}

.unmatched-question--delete {
  display: none;
}

.unmatched-question:hover > .unmatched-question--delete {
  display: inline;
  cursor: pointer;
}

.unmatched-header-text,
.unmatched-question-text {
  display: flex;
  justify-content: left;
  width: 35%;
}
.unmatched-header-text {
  justify-content: center;
}
.unmatched-question-text {
  font-size: 1em;
  color: #434b65;
  overflow: auto;
  max-height: 4.1em;
}

.unmatched-header-matching,
.unmatched-dropdown {
  display: flex;
  width: 35%;
  justify-content: center;
  min-width: 300px;
}

.unmatched-header-done,
.unmatched-question-done {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
}

.highLight {
  background-color: #3a86ff;
  border-radius: 50%;
}

.highLight:hover {
  cursor: pointer;
}
.highLight .unmatched-question-congrats-li-done {
  color: white;
}

.unmatched-question-current {
  box-shadow: 0 0 11px 0 #8e8e8e;
}

.unmatched-question-congrats-li-done {
  font-size: 30px;
  margin-top: 5px;
  color: rgba(58, 134, 255, 0.5);
}

.unmatched-dropdown-header {
  display: flex;
  font-weight: bolder;
  color: #434b65;
}

.unmatched-dropdown-header-p {
  margin-left: 10px;
  line-height: 1em;
}

.unmatched-dropdown > .ui.selection.active.dropdown > .menu {
  height: 700%;
  max-height: none;
}

.unmatched-dropdown .ui.selection.active.dropdown,
.unmatched-dropdown .ui.selection.active.dropdown .menu {
  border: unset;
}
.unmatched-dropdown .ui.selection.active.dropdown {
  height: auto;
}
.unmatched-dropdown .ui.selection.active.dropdown {
  box-shadow: 1px 1px 10px rgba(116, 123, 128, 0.8);
}
.unmatched-dropdown .ui.selection.active.dropdown .menu {
  box-shadow: 1px 2px 6px rgba(116, 123, 128, 0.8);
}

.unmatched-dropdown .ui.selection.active.dropdown .item {
  color: #434b65;
}
.unmatched-dropdown .ui.search.dropdown > .text {
  margin-top: 1px;
}

.unmatched-dropdown .icon {
  width: 100%;
}

.unmatched-dropdown .text {
  word-wrap: break-word;
}

.unmatched-bottom {
  position: fixed;
  bottom: 2em;
  right: 9.5em;
}

.unmatched-dropdown-preview {
  position: relative;
  text-align: left;
  cursor: pointer;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1em;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  width: 22em;
  min-height: 2.71428571em;
  background: #fff;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: #434b65;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429em;
  transition: box-shadow 0.1s ease, width 0.1s ease;
}

.unmatched-dropdown-preview-icon {
  position: absolute;
  right: 0.5em;
}

.ValidateButton {
  display: flex;
  align-items: center;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  background-color: white;
  border-radius: 8px;
  padding: 0 1em;
  box-shadow: 0 0 11px 0 #8E8E8E;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.ValidateButton-button {
  border-radius: 8px;
  font-size: 1em;
  padding: 0 1.5em;
  margin: 0.5em 0 0.5em 2em;
  height: 3.5em;
  border: none;
  color: white;
}

.ValidateButton-button.activated-button {
  background-color: #3A86FF;
}

.ValidateButton-button.deactivated-button {
  background-color: #D8D8D8;
}

.ValidateButton-done_icon {
  color: #3A86FF;
  font-size: 2em;
  margin-right: 0.35em;
}

.LogSelected {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.LogSelected-done_icon {
  color: #3A86FF;
  font-size: 1.7em;
  margin-right: 0.35em;
}

.Monitor-ListQna {
  background-color: #f9f8f8;
  flex-direction: column;
  display: flex;
}

.emptyLogs {
  background-color: #f9f8f8; 
}

.emptyLogs h1 {
  font-family: 'Open Sans', Helvetica, Arial;
  text-align: center;
  text-shadow: 1px 1px 0 rgba(167,32,32,.33);
  font-size: 1em;
  padding-top: 1em;
  color: #454545;
}

.Monitor-ToValidate {
  color: #434b65de;
  font-size: 14px;
  margin-left: 5%;
  margin-top: 2em;
  margin-bottom: 1em;
  font-family: 'Open Sans', Helvetica, Arial;
  width: 30%;
}

.Monitor-Qna {
  margin: auto;
  margin-top: 1em;
  margin-bottom: 2%;
  width: 90%;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 1px 1px 10px rgba(116, 123, 128);
}

.Monitor-Qna-empty-container {
  margin: 2em;
}

.Monitor-Qna-empty-container--forwardIcon {
  margin-left: 8px;
}

.Monitor-Qna--title {
  margin-top: 1em;
  font-size: 21px;
  font-weight: bold;
  line-height: normal;
  padding-left: 1.6em;
  padding-bottom: 1em;
  font-family: 'Open Sans', Helvetica, Arial;
  color: #434b65;
  border-bottom: 1px solid #f9f9f9;
}

.Monitor-Qna-confirm {
  border-top: 1px solid #dadada0d;
}

.Monitor-Qna-confirm button {
  background-color: #3a86ff;
  border: none;
  color: white;
  border-radius: 8px;
  margin: 1.6em 0px 17px 24px;
  width: 7.4em;
  height: 2.9em;
  font-size: 15px;
  font-family: 'Open Sans', Helvetica, Arial;
}

.Monitor-Qna-confirm button:hover {
  cursor: pointer;
  background-color: #009fff;
}

.Monitor-MatchedQuestion {
  display: flex;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  border-bottom: 1px solid #dadada26;
  width: 100%;
}

.Monitor-MatchedQuestion:hover {
  cursor: pointer;
  background-color: #f9f9f9;
}

.clickable:hover {
  cursor: pointer;
}

.hoverVisible:hover .Monitor-MatchedQuestion--delete {
  visibility: visible;
}

.Monitor-MatchedQuestion--delete {
  visibility: hidden;
  color: #8e9296;
  font-size: 10px;
  margin-left: 32px;
  padding-top: 15px;
}

.Monitor-MatchedQuestion--label {
  color: #434b65cc;
  font-size: 16px;
  margin-left: 1em;
  min-height: 3em;
  padding-top: 15px;
  font-family: 'Open Sans', Helvetica, Arial;
}

.MatchedQuestionCrossed {
  text-decoration: line-through;
  color: #434b6585;
}


.AddButton-plusIcon {
    margin-right: 8px;
}

.AddQnA {
  display: flex;
}

.AddQnA >*:not(:last-child) {
  margin-right: 20px;
}

.AddQnA-questionEditor {
  width: 450px;
}

.SuccessfulLabel-container {
    display: flex;
    align-items: center;
    color: #4c5570;
    font-family: OpenSans, sans-serif;
}

.SuccessfulLabel-link {
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #4c5570;
    font-weight: bold;
    text-decoration: underline;
}

.SuccessfulLabel-done_icon {
    color: #3A86FF;
    font-size: 1.7em;
    margin-right: 0.35em;
}

.OutOfScope {
  background-color: #f9f9f9;
}

.OutOfScope-header {
  margin: 30px 0 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.OutOfScope-title {
  margin: 0;
  display: flex;
  align-items: center;
}

.OutOfScope-title >:not(:first-child) {
  margin-left: 1.65em;
}

.OutOfScope-title h1 {
  margin: 0;
  font-size: 2em;
  color: #4a5771;
}

.OutOfScope-container {
  padding: 0;
  box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
  border-radius: 8px;
  color: #434B65;
  -webkit-margin-before: 1.5em;
          margin-block-start: 1.5em;
  -webkit-margin-after: 1.5em;
          margin-block-end: 1.5em;
}

.OutOfScope-empty-container--forwardIcon {
  margin-left: 8px;
}

.OutOfScope-log {
  width: 100%;
  padding: 10px 40px 10px 10px;
  background-color: white;
  align-items: center;
  display: flex;
  list-style: none;
  border-bottom: 1px solid rgba(218, 218, 218, 1);
}

.OutOfScope-log:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.OutOfScope-log:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.OutOfScope-log:hover {
  cursor: pointer;
  background-color: #f9f9f9;
}

.OutOfScope-select {
  margin: 0 4px;
}

.OutOfScope-select--icon {
  visibility: hidden;
  color: #3A86FF;
  font-size: 1.7em;
  margin-right: 0.35em;
}

.OutOfScope-select--icon.selected,
.OutOfScope-log:hover .OutOfScope-select--icon.selected {
  visibility: visible;
  opacity: 1;
}

.OutOfScope-log:hover .OutOfScope-select--icon {
  visibility: visible;
  opacity: 0.35;
}

.OutOfScope-text {
  margin: 0;
}

.OutOfScope-date {
  flex-grow: 2;
  text-align: end;
}

.ButtonContent {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  margin: 16px 0;
  padding: 16px;
  border-radius: 8px;
}

.ButtonContent-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.ButtonContent-row--title {
  margin-right: 2em;
  height: 3em;
  flex-basis: 30%;
}

.ButtonContent-dropdown--qa {
  width: 100%;
}
.ButtonContent-dropdown--qa.ui.selection.dropdown {
  border: solid 1px #ececec;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ButtonContent-actions {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}

.ButtonContent-actions--delete:hover {
  color: #DD4F75;
  cursor: pointer;
}

.ButtonContent-row--url {
  height: 3em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ButtonContent-row-url--container {
  display: flex;
  flex-grow: 1;
}

.ButtonContent-row-url--container div.ui.selection.dropdown.naming-dropdown {
  margin-bottom: 0;
  padding: 0.85em;
  border-radius: 8px 0 0 8px;
}

@media (max-width: 960px) {
  .ButtonContent, .ButtonContent-row {
    flex-wrap: wrap;
  }

  .ButtonContent-row > * {
    margin-top: 16px;
    margin-left: 0;
    margin-right: 0;
  }
}

.imageContentEditor-dropzone {
  background-size: cover;
  background-color: #f6f6f6;
  height: 180px;
  width: 250px;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 8px;
  text-align: center;
  color: #B2B2B2;
  padding: 50px 20px;
  margin: 16px 0 25px;
  outline: none;
  cursor: pointer;
}

.imageContentEditor-dropzone:active {
  border: transparent;
}

.imageContentEditor-container {
  position: relative;
  width: 250px;
}

.imageContentEditor-icon {
  position: absolute;
  right: 0px;
  margin: 16px;
  cursor: pointer;
}

.imageContentEditor-image {
  position: absolute;
  left: 0px;
  margin: 16px;
  margin-left: 25px;
  cursor: pointer;
}

.imageContentEditor-dropzone-forbidden {
  border-color: red;
  padding: 12px;
}

.imageContentEditor-dropzone-forbidden {
  border-color: green;
}

.imageContentEditor-dropzone-placeholder {
  background-color: white;
  border-radius: 8px;
  height: 110px;
  padding: 10px 8px;
  cursor: pointer;
}

.TextContent {
  background-color: #f6f6f6;
  margin: 16px 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.TextContent-icons-container {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}

.TextContent-delete:hover {
  cursor: pointer;
  color: #DD4F75;
}

.TextContent-text {
  padding: 8px;
}

@media (max-width: 960px) {
  .TextContent {
    flex-wrap: wrap;
  }
}

.SkillContentEditor {
  background-color: #F6F6F6;
  border-radius: 8px;
  padding: 1.2em;
  margin: 1.2em 0;
}

.SkillContentEditor-top_bar {
  display: flex;
  justify-content: space-between;
}

.SkillContentEditor-top_bar--content_icon {
  width: 2em;
  height: 2em;
  color: #434B65;
  font-weight: 600;
}

.SkillContentEditor-top_bar--remove_icon {
  width: 1.5em;
  height: 1.5em;
  color: #434B65;
  font-weight: bold;
  cursor: pointer;
}

.SkillContentEditor-content {
  display: flex;
  flex-direction: column;
}

.SkillContentEditor-content--text {
  font-size: 1em;
}

.SkillContentEditor-content--bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SkillContentEditor-content--bottom---select {
  background-color: white;
  width: 25%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.SkillContentEditor-content--bottom---link {
  font-weight: bold;
  padding: 0.6em 0.8em;
  border-radius: 8px;
  border: 2px solid #3A86FF;
  opacity: 0.75;
  color: #3A86FF;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.SkillContentEditor-content--bottom---link_text {
  margin: 0 1em 0 0;
  padding: 0;
}
.SkillContentEditor-content--bottom---link_icon {
  font-size: 2em;
}

.SkillContentEditor {
  background-color: #F6F6F6;
  border-radius: 8px;
  padding: 1.2em;
  margin: 1.2em 0;
}

.SkillContentEditor-top_bar {
  display: flex;
  justify-content: space-between;
}

.SkillContentEditor-top_bar--content_icon {
  width: 2em;
  height: 2em;
  color: #434B65;
  font-weight: 600;
}

.SkillContentEditor-top_bar--remove_icon {
  width: 1.5em;
  height: 1.5em;
  color: #434B65;
  font-weight: bold;
  cursor: pointer;
}

.SkillContentEditor-content {
  display: flex;
  flex-direction: column;
}

.SkillContentEditor-content--text {
  font-size: 1em;
}

.SkillContentEditor-content--bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SkillContentEditor-content--bottom---select {
  background-color: white;
  width: 25%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.SkillContentEditor-content--bottom---link {
  font-weight: bold;
  padding: 0.6em 0.8em;
  border-radius: 8px;
  border: 2px solid #3A86FF;
  opacity: 0.75;
  color: #3A86FF;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.SkillContentEditor-content--bottom---link_text {
  margin: 0 1em 0 0;
  padding: 0;
}
.SkillContentEditor-content--bottom---link_icon {
  font-size: 2em;
}

.SimpleAnswerEditor-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
}

.SimpleAnswerEditor-checkbox {
    padding-bottom: 25px;
}

.CarouselCard-container {
  min-width: 17em;
}

.CarouselCard-icon--container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
}

.CarouselCard-card--container {
  padding: 1em;
  background-color: white;
  border-radius: 8px;
}

.CarouselCard-input-title,
.CarouselCard-input-description {
  margin-bottom: 1em;
}

.CarouselCard-input-title {
  height: 3em;
}

.CarouselCard-input-description {
  height: 6em;
}

.CarouselCard-dropzone {
  background-size: cover;
  height: 3em;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 8px;
  text-align: center;
  color: #B2B2B2;
  outline: none;
  cursor: pointer;
  border: dashed 1px #ececec;
  padding-top: 0.7em;
  width: 100%;
}

.CarouselCard-dropzone:active {
  border: transparent;
}

.CarouselCard-dropzone-forbidden {
  border-color: red;
  padding: 12px;
}

.CarouselCard-dropzone-forbidden {
  border-color: green;
}

.CarouselCard-dropzone-placeholder {
  cursor: pointer;
}

.CarouselCard-button {
  width: 100%;
  border: 0;
  color: #EEE;
  border-radius: 8px;
  background: #434b65;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.CarouselCard-button--add {
  margin-top: 3px; /* these 3px made me crazy, initially the svg icon has a 20x20 size, but inside the div it's 20x23 so it's pretty shitty solution I know but if you got a solution I will remove it with great pleasure :3*/
  margin-right: 2em;
}

.CarouselCard-buttonContent--container {
  background-color: #f6f6f6;
  border-radius: 8px;
  margin: 2em;
  padding: 1.5em 1.5em 3em 1.5em;
}

.CarouselCard-TextArea {
  margin-bottom: 1em;
}

.CarouselCard-icon--delete {
  cursor: pointer;
}

.CarouselCard-image--container {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.CarouselCard-emove {
  margin-left: 1em;
  cursor: pointer;
  text-decoration: underline;
}

.CarouselContentEditor--container {
  position: relative;
  display: flex;
  background-color: #f6f6f6;
  border-radius: 8px;
  padding: 1em 2em;
  margin-bottom: 1em;
}

.CarouselContentEditor--container>.CarouselCard-container {
  margin-right: 2em;
}

.CarouselContentEditor-icon--add {
  cursor: pointer;
  padding: 5px 4px 4px 4px;
  font-size: 2em;
  color: #434b65;
}

.CarouselContentEditor-icon--delete {
  cursor: pointer;
  position: absolute;
  top: 0.8em;
  right: 1em;
}
.CarouselContentEditor-icon--carousel {
  position: absolute;
  top: 0.8em;
  left: 1em;
}

.CarouselContentEditor-carouselcard--container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-self: flex-start;
  justify-content: start;
}

.CarouselContentEditor-carouselcard--container>.CarouselCard-container {
  margin-right: 1em;
  margin-bottom: 1em;
}

.CarouselCard-icon-empty--container {
  margin-top: 2.4em;
}

.HubIcon {
  background-size: 1.8em;
  padding: 0 1.5em;
  margin-top: 3px;
  background-position: top;
  background-origin: content-box;
  background-repeat: no-repeat;
  fill:#434B65; 
}

.HubIcon-svg {
  padding-top: 0;
}

.CreateContentMenu-box.disabled {
  cursor: default;
}

.CreateContentMenu-box.disabled>.CreateContentMenu-icon {
  opacity: 0.5;
}

.CreateContentMenu-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  color: #747a8d;
  border-right: solid 2px #ececec;

  height: 80px;
  width: 100px;
  background-color: white;
  padding: 15px;
}

.CreateContentMenu ul > :first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.CreateContentMenu ul > :last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: none;
}

.CreateContentMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: solid 2px #ececec;
  border-radius: 12px;
}


.CreateContentMenu-icon {
  font-size: 25px;
  color: #434b65;
}

.CreateContentMenu-text {
  font-size: 12px;
}

.CreateContentMenu-title {
  font-size: 14px;
  color: #747a8d;
}

.QRAnswerEditor {
    display: flex;
    height: 100%;
}

.AnswerEditor {
    display: flex;
    height: 100%;
}

.QuickReply {
    flex-grow: 1;
    background-color: #f6f6f6;
    margin-bottom: 30px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.QuickReplyContent--button {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.QuickReplyContent-text--button {
    margin: 10px 10px 10px 0;
    align-content: center;
    width: 250px;
    max-width: 15em;
    border: solid 1px #ececec;
    font-weight: 500;
    font-size: 1.2em;
    border-radius: 8px;
}

.QuickReplyContent-text--button .TextInput {
    padding: 0 1.5em;
}

.QuickReplyContent-text--button input{
    text-align: center;
}

.QuickReplyContent-remove--button {
    position: absolute;
    right: 1.3em;
    visibility: hidden;
    color: #434b65;
}

.QuickReplyContent-column--button:hover .QuickReplyContent-remove--button {
    visibility: visible;
}

.QuickReplyContent-remove--button:hover {
    cursor: pointer;
}

.QuickReply-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.QuickReply-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-self: flex-start;
    width: 100%;
}

.QuickReply-icons-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}


.QuickReplyContent-column--button {
    display: flex;
    flex-direction: column;
}

.QuickReply-answer {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: solid 1px #ececec;
    border-radius: 8px;
    padding: 20px;
}

.QuickReply-answer--button {
    width: 100%;
    border: 0;
    color: #EEE;
    background: #434b65;
    border-radius: 3px;
    padding: 8px 18px;
}

.QuickReply-answer--subtitle {
    color: #434b65;
    font-weight: bold;
    opacity: 0.5;
    font-size: 14px;
    align-self: flex-start;
}

.QuickReply-actions--add {
    cursor: pointer;
    padding: 5px 4px 4px 4px;
    font-size: 2em;
    color: #434b65;
}

.TextContent-delete--icon {
    cursor: pointer;
}

.SimpleAnswerEditor {
    display: flex;
    flex-direction: column-reverse;
}

.quickReply-answer-dropdown-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.QuickReply-answer-dropdown>.visible.menu.transition {
    max-width: 101%;
    border: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.QuickReply-answer-dropdown>.visible.menu.transition::-webkit-scrollbar {
    width: 3px;
}


.ui.dropdown, .QuickReply-answer-dropdown {
    display: flex;
    align-items: center;
}

.ui.scrolling.dropdown.QuickReply-answer-dropdown>div.default.text {
    color:#434b65;
    opacity: 0.5;
}

.QuickReply-answer-dropdown {
    max-width: 15em;
    font-size: 14px;
    font-weight: bold;;
    height: 3em;
    color: #434b65;
    text-align: center;
}

.ui.selection.scrolling.dropdown.QuickReply-answer-dropdown {
    border: none;
}
.ui.active.visible.selection.scrolling.dropdown.QuickReply-answer-dropdown {
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
}

.QuickReply-answer-dropdown .text {
    overflow: hidden;
    width: 100%;
    text-align: center;
    color: #434b65;
}

.QuickReply-answer-dropdown>.visible.menu.transition {
    max-height: 9em;
}

.AnswerModal--modal>.SimpleAnswerEditor>.SimpleAnswerEditor-content {
    padding: 20px;
}

.AnswerModal--modal>.SimpleAnswerEditor>.CreateContentMenu {
    padding: 0 0 0 20px;
}

.AnswerModal--button {
    margin-right: 2em;
    margin-bottom: 1em;
    min-width: 6em;
    background-color: #3a86ff;
    color: white;
    margin-left: auto;
    font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
}

.AnswerModal--modal.ui.modal>:last-child {
    border-radius: 8px;
}

.AnswerModal--button:hover {
    background-color: #4e556b;
}

.AnswerModal--icon {
    margin-right: 10px;
}

.AnswerEditor {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: column;
  margin: 30px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
  border-radius: 8px;
  padding: 30px 30px;
}

.AnswerEditor-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
}

.AnswerEditor-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
}

.ui.message.SaveError {
    display:block;
    overflow:hidden;
    background-color: #fff6f6;
}

.ui.message.SaveError.floating-style {
    box-shadow: 0 0 0 1px rgba(159,58,59,.22) inset, 0 2px 4px 0 rgba(159,58,59,.12), 0 2px 10px 0 rgba(159,58,59,.15);
}

.Header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 30px;
}

.Header--saveButton {
    width: 100px;
    min-height: 2.5em;
    border-radius: 8px;
    font-weight: bold;
}

.Header--saveButtonError {
    display: none;
}

.Header--saveButton[disabled]:hover + .Header--saveButtonError {
    display: initial;
    position: absolute;
    right: 10em;
}

.Header--icon {
    margin-right: 10px;
}

.QaAnswerPage {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-content: center;
    justify-items: center;
}
.QaAnswerPage > div:first-child {
    padding-bottom: 0;
}

.SimpleAnswerEditor-checkbox {
    font-size: 14px !important;
}

.AddSectionButton-plusIcon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 10px;
}

.AddSectionButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 8px;
  border: solid 2px #3a86ff;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  color: #3a86ff;
  line-height: 1.75em;
  padding: 0.25em 0.75em;
  opacity: 0.75;
}

.AddSectionButton:hover {
  opacity: 1;
}

.CreateSection {
    width: 100%;
    margin-bottom: 32px;
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
    background-color: white;

    line-height: 2em;
    min-height: 4em;
    padding: 1em;
}

.CreateSection--input {
    border: none;
    width: 30%;
    height: 100%;
    font-size: 1.1em;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #4a5771;
}

.CreateSection--input::-webkit-input-placeholder {
    opacity: 0.19;
}

.CreateSection--input::placeholder {
    opacity: 0.19;
}

.CreateSection .DotLoader {
    font-family: 'Open Sans', sans-serif;
    opacity: 0.5;
    color: #4a5771;
}

.ImportButton {
    border-radius: 8px;
    width: 180px;
    min-height: 40px;
    color: white;
    border: 2px solid #3a86ff;
    background-color: #3a86ff;
    padding: 6px;
}

.ImportButton.ImportButton--disabled {
    background-color: rgba(58, 134, 255, 0.2);
    cursor: default;
}

.ImportButton:not(.ImportButton--disabled):hover {
    color: #3a86ff;
    background-color: white;
    cursor: pointer;
    opacity: 0.75;
    transition-duration: 0.25s;
}

.ImportButton-icon {
    margin-left: 20px;
    margin-right: 10px;
}

.ImportButton-import--label {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    font-size: 1em;
    font-weight: bold;
}

.ImportButton:not(.ImportButton--disabled):hover > label {
    cursor: pointer;
}

#ImportButton-import--input {
    display: none;
}

.ImportButton-top--input {
    margin-top: 0;
}

.QaTopBar-container {
    width: 100%;
    margin-bottom: 3em;
}

.QaTopBar-top {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
}

.QaTopBar-top > *:not(:last-child) {
    margin-right: 1em
}

.QaTopBar-top-input {
    flex-grow: 1;
}

.QaQuestionPage-section-questionList {
  width: 100%;
  margin-bottom: 32px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
}

.QaQuestionPage-section-questionList:last-child {
  margin-bottom: 0;
}

.Question:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.QaQuestionPage-section-deleting,
.QaQuestionPage-section-header {
  display: flex;
  align-items: center;
  padding: 1em;
  line-height: 2em;
  height: 4em;
}

.QaQuestionPage-section-header {
  cursor: pointer;
}

.QaQuestionPage-section-deleting>.DotLoader {
  color: #e2574c;
}
.QaQuestionPage-section-deleting>.DotLoader>.lds-ellipsis div {
  background: #e2574c;
}

.QaQuestionPage-section-title {
  margin: 0;
  flex-grow: 1;
}

.QaQuestionPage-section-arrow-icon {
  transition-duration: 300ms;
}

.QaQuestionPage-section-arrow-icon.open {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.QaQuestionPage-section-menu {
  line-height: normal;
}

.QaQuestionPage-section-menu > i {
  overflow-x: unset;
  text-overflow: unset;
  white-space: unset;
}


.QaQuestionPage-section_input-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-right: 1em;
}
.QaQuestionPage-section-header-font.TextInput-container {
  height: 1.8em;
  border: none;
}

.QaQuestionPage-section-header-font,
.QaQuestionPage-section-header-font > .TextInput {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  color: #4a5771;
}


.QaQuestionPage {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.QaQuestionPage > div {
  margin-bottom: 32px;
}

.QaQuestionPage > div:first-child {
  margin-bottom: 3em;
}

.QaQuestionPage > div:last-child {
  margin-bottom: 0;
}

.EmailContent {
  background-color: #f6f6f6;
  margin: 16px 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.EmailContent-icons-container {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}

.EmailContent-delete:hover {
  cursor: pointer;
  color: #DD4F75;
}

.EmailContent-text {
  padding: 8px;
}

@media (max-width: 960px) {
  .EmailContent {
    flex-wrap: wrap;
  }
}

.FileContent {
  background-color: #f6f6f6;
  margin: 16px 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.FileContent-icons-container {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}

.FileContent-delete:hover {
  cursor: pointer;
  color: #DD4F75;
}

.FileContent-text {
  padding: 8px;
}

@media (max-width: 960px) {
  .FileContent {
    flex-wrap: wrap;
  }
}

.FormBuilder-containers {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 0 var(--spacing-xxl-2) var(--spacing-xxl-2);
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
  border-radius: 8px;
}

.FormBuilder-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xxl-2);
}

.FormBuilder-content>.AnswerEditor {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.FormBuilder-content--separator {
  border: solid 1px;
  width: 100%;
  color: #e9e9e9;
}

.FormBuilder-containers--title {
  padding-top: var(--spacing-xxl-2);
  padding-left: var(--spacing-xxl-2);
  padding-bottom: var(--spacing-m);
}

.FormResponse-component-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 0 var(--spacing-xxl-2) var(--spacing-xxl-2);
  background-color: #fff;
  box-shadow: 0 0 5px 0 hsla(0,0%,71%,.5);
  border-radius: 8px;
}

.FormResponse-component-title {
  padding: var(--spacing-xxl-2);
  padding-bottom: var(--spacing-m);
}

.FormResponse-email-container {
  display: flex;
  align-items: center;
  padding: var(--spacing-xxl-2);
}

.FormResponse-email-container > .FormInput {
  width: 20em;
}

.FormResponse-zapier-container {
  display: flex;
  align-items: center;
  padding: var(--spacing-xxl-2);
  padding-top: 0;
}

.FormResponse-zapier-container > .FormInput {
  width: 20em;
}

.FormResponse-title {
  font-weight: bold;
  margin-left: var(--spacing-m);
  margin-bottom: 0;
}

.FormResponse-description {
  margin: 0 var(--spacing-m) 0 var(--spacing-m);
}

.FormResponse-errorMessage {
  padding: var(--spacing-m) var(--spacing-xxl-2) var(--spacing-xxl-2) var(--spacing-xxl-2);
}

select {
  padding: 0 1em 0 0;
  border: none;
  margin: 0;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  cursor: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.dropdown__label-container {
  display: flex;
  flex-direction: column;
  border-left: solid 2px hsla(0, 0%, 59%, 0.08);
  margin: 0.563em 1.125em 0.125em 0;
}

.dropdown__text {
  margin: 0 19.375em 1.25em 1.125em;
  color: hsl(226, 58%, 25%);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.765em;
  font-weight: bold;
}

.dropdown__select::after {
  width: 1.063em;
  height: 0.563em;
  margin-right: 1em;
  background-color: #434b65;
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
          clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  content: "";
  justify-self: end;
}

.dropdown__select {
  display: grid;
  align-items: center;
  margin-right: 5em;
  grid-template-areas: "select";
}

select,
.dropdown__select::after {
  grid-area: select;
}

.dropdown__standard-select {
  padding: 0.7em 1.563em 0.7em 1.688em;
  border: solid 2px hsl(0, 0%, 97%);
  margin: 0 0 0 0.75em;
  background-color: var(--light);
  border-radius: 8px;
  color: hsl(226, 20%, 33%);
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
}

.zendesk-support-form {
  margin: 1em 2em;
}

.zendesk-support-form__input-container {
  display: flex;
  align-items: center;
}

.input-container__label {
  position: relative;
  display: inline-block;
  width: 2.75em;
  height: 1.625em;
}

.input-container__checkbox {
  width: 0;
  height: 0;
  opacity: 0;
}

.input-container__slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: hsl(0, 0%, 84%);
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;
}

.input-container__slider::before {
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 1.125em;
  height: 1.125em;
  background-color: hsl(0, 0%, 100%);
  border-radius: 50%;
  content: "";
  transition: 0.2s;
}

.input-container__checkbox:checked + .input-container__slider {
  background-color: hsl(217, 100%, 62%);
}

.input-container__checkbox:checked + .input-container__slider::before {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
}

.input-container__logo {
  width: 1.125em;
  height: 0.875em;
  margin: 0.375em 0.688em 0.375em 0.75em;
}

.input-container__text {
  margin: 0.188em 0 0.063em 0;
  color: hsl(226, 20%, 33%);
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  font-weight: bold;
}

.zendesk-support-form___interractive-container {
  display: flex;
  margin-top: 1em;
}

.dropdown-label-container {
  display: flex;
  flex-direction: column;
  border-left: solid 2px hsla(0, 0%, 59%, 0.08);
  margin: 0.563em 1.125em 0.125em 0;
}

.dropdown-text {
  margin: 0 19.375em 1.25em 1.125em;
  color: hsl(226, 58%, 25%);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.765em;
  font-weight: bold;
}

.dropdown-select {
  padding: 0.7em 1.563em 0.7em 1.688em;
  border: solid 2px hsl(0, 0%, 97%);
  margin: 0 7.813em 0 0.75em;
  background-color: var(--light);
  border-radius: 8px;
  color: hsl(226, 20%, 33%);
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
}

.interractive-container__input-label {
  display: flex;
  flex-direction: column;
  border-left: solid 2px hsla(0, 0%, 59%, 0.08);
  margin: 0.563em 1.125em 0.125em 0;
}

.interractive-container__input-text {
  margin: 0 0 1.25em 1.125em;
  color: hsl(226, 58%, 25%);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.765em;
  font-weight: bold;
}

.interractive-container__input-tags {
  padding: 0.7em 1.563em 0.7em 1.688em;
  border: solid 0.8px hsl(0, 0%, 93%);
  margin: 0 0 0 1.125em;
  background-color: hsl(0, 0%, 100%);
  border-radius: 6.4px;
}

:root {
  --skill-button-size: 15em;
}

.skill-default--button {
  border-radius: 8px;
  border: solid 1px #e4e4e4;
  height: 15em;
  height: var(--skill-button-size);
  width: 15em;
  width: var(--skill-button-size);
  box-shadow: 0 0 26px rgba(220, 220, 220, 0.6);
  margin-right: 3em;
  margin-bottom: 3em;
  cursor: pointer;

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;

  background-image: none;
  background-position: center;
  background-origin: content-box;
  background-repeat: no-repeat;
  padding-bottom: 64px;
}

.skill-onboarding-button {
  background-image: url('/img/onboarding.svg');
  background-size: 6.5em; /* onboarding image does not contain border while fallback does 😔 */
}

.skill-fallback-button {
  background-image: url('/img/fallback.svg');
  background-size: 8em;
}

.skill-human-handover--button {
  background-image: url('/img/human-handover.png');
  background-size: 6em;
}

.skill-tap-flow--button {
  background-image: url('/img/tap-flow.svg');
  cursor: not-allowed;
}

.skill-rgpd--button {
  background-image: url('/img/ufo.svg');
  cursor: not-allowed;
}

.skill-form--button {
  background-image: url('/img/satellite-dish.svg');
  background-size: 6em;
}

.skill-advanced--button {
  background-image: url('/img/space-capsule.svg');
  background-size: 6em;
  text-align: center;
}

.create-skill-form--button {
  cursor: pointer;
  padding-top: 4em;
  padding-bottom: 0;
}

.create-skill-form--icon {
  width: 5em;
  height: 5em;
  opacity: 0.17;
}

.create-skill-form--input {
  margin-bottom: 1em;
}

.create-skill-form--modal-title {
  color: #434b65;
  font-family: 'Open Sans', Helvetica, Arial;
}

.skill-form-download--icon {
  position: relative;
  bottom: 2em;
  left: calc(15em / 2 - 25px);
  left: calc(var(--skill-button-size) / 2 - 25px);
  color: #3a86ff;
  padding: 2px;
  opacity: 0.75;
  flex-shrink: 0;
}

.skill-form-download--icon:hover {
  opacity: 1;
}

.skill-form-download-data {
  background-color: black;
  opacity: 0.75;
  color: #e4e4e4;
  font-size: larger;
  font-weight: 600;
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: -3em;
  padding-top: 3em;
  padding-bottom: 3em;
  text-align: center;
}

.customSkill {
  width: 26em;
  height: 27em;
}

.skill-add-form--label {
  font-size: 1.3em;
  color: #1a2b63;
  font-weight: bold;
  opacity: 0.17;
}

.skill-default--label,
.skill-advanced--label,
.skill-form--label {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.3em;
  color: #1a2b63;
  font-weight: bold;
  top: calc(15em*2.7 /5);
  top: calc(var(--skill-button-size)*2.7 /5);
  text-align: center;
  height: 48px;
}

.SkillPage {
  margin: 2em;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.skill-trash-container {
  display: none;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  position: relative;
  left: calc(15em / 2 - 25px);
  bottom: 1.5em;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  color: #3f4046;
}

.skill-trash-container:hover .skill-trash-icon {
  fill: #000;
}

.skill-advanced-container {
  position: relative;
  margin-right: 3em;
  margin-bottom: 3em;

  background-position: center;
  background-origin: content-box;
  background-repeat: no-repeat;
  padding-bottom: 64px;

  height: 15em;

  height: var(--skill-button-size);
  width: 15em;
  width: var(--skill-button-size);
}

.skill-advanced-container .skill-default--button {
  margin-bottom: 0;
  margin-right: 0;
  padding: 0;
  background-position: unset;
  background-origin: unset;
  background-repeat: unset;
}

.skill-advanced-trash-container {
  position: absolute;
  left: 170px;
  top: 1em;
  bottom: unset;
}

.skill-form-trash-container--no-download {
  bottom: 2em;
}

.skill-modal-element__li-title {
  margin-bottom: 0;
}

.skill-modal-element__li {
  margin-bottom: 1em;
}

.skill-form--button:hover .skill-trash-container,
.skill-advanced-container:hover .skill-trash-container {
  display: flex
}

.SkillList-title {
  color: #1a2b63;
  font-size: 1.4em;
  font-weight: lighter;
}

.SkillList-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Collapsible-container {
  display: flex;
  flex-direction: column;
  margin: 0 2em 2em;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
  border-radius: 8px;
}

.Collapsible-container-header {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  color: #1a2b63;
  font-weight: 700;
  cursor: pointer;
  padding: 1.5em;
}

.Collapsible-container-title {
  margin: 0;
  margin-left: 2em;
  flex-grow: 1;
}

.Collapsible-icon-round {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.8em;
  width: 1.8em;
  background-color: #1a2b63;
  border-radius: 1.8em;
}

.Collapsible-icon-round-loop {
  color: white;
}

.Collapsible-content-container {
  border-top: solid 1px #e9e9e9;
  padding: 2em;
}

.Collapsible-container-arrow-icon {
  transition-duration: 300ms;
}

.Collapsible-container-arrow-icon.open {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.SkillBuilder-containers {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 0 2em 2em;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
  border-radius: 8px;
  padding: 2em;
}

.SkillBuilder-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.SkillBuilder-create-question-icon {
  padding-right: 2em;
}

.SkillBuilder-scenario-container {
  background-color: #f6f6f6;
  padding: 1.5em;
  margin-bottom: 1em;
  border-radius: 8px;
}

.SkillBuilder-scenario-container .TextContent,
.SkillBuilder-scenario-container .ButtonContent,
.SkillBuilder-scenario-container .SkillContentEditor {
  border: solid 2px white;
  box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.4);
}

.SkillBuilder-scenario-simpleAnswerEditor-container .AnswerEditor {
  box-shadow: none;
  padding: 0;
  margin: 0;
  border-radius: 0;
  background-color: #f6f6f6;
}

.SkillBuilder-scenario-simpleAnswerEditor-container .AnswerEditor .SimpleAnswerEditor-content {
  background-color: #f6f6f6;
}

.SkillBuilder-scenario-container > div > .CreateContentMenu {
  margin-top: 1em;
}

.SkillBuilder-scenario-title-container {
  display: flex;
  align-items: center;
  align-self: flex-end;
}

.SkillBuilder-scenario-label-containers {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
  align-items: center;
}

.SkillBuilder-scenario-label-containers-arrow-icon {
  margin-left: auto;
  margin-right: 1em;
  justify-self: flex-end;
  transition-duration: 300ms;
}

.SkillBuilder-scenario-label-containers-arrow-icon.open {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.SkillBuilder-scenario-label-title {
  padding: 0.7em;
  width: 12em;
  background-color: white;
  border: solid 1px #ececec;
  border-radius: 20em;
  text-align: center;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin-right: 1em;
  color: #434b65;
}

.SkillBuilder-scenario-label-trash {
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
}

.SkillBuilder-question, .SkillBuilder-answers {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  color: #1a2b63;
  font-weight: 700;
  cursor: pointer;

}

.SkillBuilder-question-title {
  margin-left: 2em;
}

.QuestionEditor {
  background-color: #f6f6f6;
  margin: 16px 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.QuestionEditor-question--title {
  flex-grow: 2;
  padding-left: 1em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.2em;
  color: #1a2b63;
  font-weight: 700;
}

.QuestionEditor-icons-container {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}

.QuestionEditor-delete--icon:hover {
  cursor: pointer;
  color: #DD4F75;
}

.Quick-reply {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: 2em;
}

.Quick-reply--button---text {
  border-radius: 17px;
  text-align: center;
}

.Quick-reply--button---text .TextInput {
  text-align: center;
}

.Quick-reply--button--remove {
  position: absolute;
  bottom: 0.6em;
  right: 1em;
  visibility: hidden;
  color: #434b65;
}

.Quick-reply--button-container {
  margin: 1em;
}

.Quick-reply--button {
  position: relative;
}

.Quick-reply--button:hover .Quick-reply--button--remove {
  visibility: visible;
}

.Quick-reply--button--remove:hover {
  cursor: pointer;
}

.Quick-reply--add {
  cursor: pointer;
  padding: 4px 4px 4px 4px;
  font-size: 1.5em;
  color: #434b65;
  font-weight: bold;
}

.Quick-reply-keyword-container {
  position: relative;
  display: flex;
  align-items: center;
}

.Quick-reply-keyword-container>.ui.selection.dropdown.QuickReply-answer-dropdown {
  background-color: transparent;
}

.Quick-reply-keyword-container .quickReply-answer-dropdown-text {
  padding-right: .3em;
}

.ui.selection.scrolling.dropdown.QuickReply-answer-dropdown>.dropdown.icon {
  line-height: 2em;
}

.Quick-reply-keyword-container .ui.scrolling.dropdown.QuickReply-answer-dropdown>div.default.text {
  line-height: 1.5em;
  padding-right: 0.3em
}

.CreateScenario {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.CreateScenario-title {
    margin-bottom: 0;
    margin-top: 2em;
    color: #616b87;
    font-weight: bold;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

.CreateScenario-icon {
    margin-left: 1em;
}

.CreateScenario-add-icon {
    padding-right: 1em;
    color: white;
}

.ui.button>.icon:not(.button) {
    opacity: 1;
}

.CreateScenario-validation {
    margin-left: 1em;
    color: white;
    background-color: #3B86FF;
}

.ui.scrolling.dropdown.CreateScenario-dropdown {
    border: solid 1px #ececec;
    border-radius: 2em;
    margin-left: 1em;
    padding: 0.7em;
}

.ui.dropdown,
.CreateScenario-dropdown {
    display: flex;
    align-items: center;
}

.CreateScenario-dropdown .text {
    overflow: hidden;
    width: 100%;
    text-align: center;
    color: #434b65;
}

.CreateScenario-dropdown>.visible.menu.transition {
    border: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.ui.scrolling.dropdown.CreateScenario-dropdown>div.default.text {
    color: #434b65;
    opacity: 0.5;
    width: 90%;
}

.ui.scrolling.dropdown.CreateScenario-dropdown {
    border-color: #ececec;
    color: #434b65;
}

.ui.active.visible.selection.scrolling.dropdown.CreateScenario-dropdown {
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgba(181, 181, 181, 0.5);
}

.ui.active.visible.selection.scrolling.dropdown.CreateScenario-dropdown {
    border-color: #ececec;
}

.channelsettings {
  max-width: 48.813em;
}

.channelsettings__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.channelsettings__content {
  padding: 1.8em 2em;
  border-radius: 8px;
  border: solid 1px #e7e7e7;
  background-color: #ffffff;
}

.channelsettings__title {
  font-size: 1.75em;
  color: #434b65;
}

.channelsettings__subtitle {
  font-size: 1em;
  font-weight: normal;
  color: #bababa;
}

.channelsettings__subtitlelink {
  text-decoration: underline;
  color: inherit;
}

.channelsettings__inputlabel {
  font-size: 0.765em;
  font-weight: bold;
  color: #1a2b63;
}

.channelsettings__input {
  display: block;
  width: 100%;
  padding: 0.75em 1em;
  border-radius: 6.5px;
  border: solid 0.7px #ececec;
  background-color: #ffffff;
  font-size: 0.765em;
  color: #434b65;
}
.ButtonLink {
  display: inline-block;
  padding: 8px 18px;
  cursor: pointer;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  border: none;
  outline: 0;
  justify-content: center;
  opacity: 0.75;
}

.ButtonLink:hover {
  opacity: 1;
}

.ButtonLink-default {
  border-radius: 8px;
}

.ButtonLink-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.ButtonLink:focus {
  outline: 0;
}
.channelcard {
  flex-direction: column;
  width: 18em;
  height: 18em;
  border-radius: 0.6em;
  border: solid 0.1em #d9d9d9;
  background-color: #fff;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
}

.channelcard--disabled {
  opacity: 0.66;
  cursor: not-allowed;
}

.channelcard__icon {
  display: block;
  width: 6em;
  height: 6em;
  margin-top: 1em;
  margin-right: auto;
  margin-left: auto;
}

.channelcard__button,
.channelcard__infotext {
  margin-top: 1.2em;
}

.channelcard__title {
  height: 2.2em;
  margin: 0;
  margin-top: 0.5em;
  text-align: center;
  font-size: 1.7em;
  font-weight: bold;
  color: var(--dusk);
}

.channelcard__button {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.channelcard__button .ButtonLink {
  padding: 0.9em 1.6em;
  text-transform: uppercase;
  font-weight: bold;
}

.channelcard__button + .channelcard__infotext {
  margin-top: 0.5em;
}

.channelcard__infotext {
  padding: 1.1em 1.6em;
  text-align: center;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: bold;
}
.channelslist__title {
  margin: 0;
  margin-top: 1em!important;
  font-size: 2em;
}

.channelslist__subtitle,
.channelslist__listtitle {
  margin: 0;
  font-family: 'Open Sans', Helvetica, Arial;
  font-size: 1.15em;
  font-weight: normal;
}

.channelslist__subtitle {
  margin-top: 0.5em;
  color: #bababa;
}

.channelslist__listtitle {
  margin-top: 3.5em;
  color: #434B65;
}

.channelslist__list {
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
}

.channelslist__listitem {
  margin-top: 1.5em;
  margin-right: 1.5em;
}

.channelslist__listitem--agentstatus>article {
  height: 18em;
  width: 18em;
  display: flex;
  flex-direction: column;
}

.channelslist__listitem--agentstatus>article>h1 {
  height: 2em;
  margin: 0;
  font-size: 1.7em;
  margin-bottom: 20px;
}

.channelslist__listitem--agentstatus>article>svg {
  height: 6em;
  margin-top: 1em;
  margin-bottom: 12px;
}

.channelslist__listitem--agentstatus>article>p {
  font-size: 0.9em;
  margin-top: 1em;
  display: flex;
  align-items: center;
}
.pageContainer--shopify a {
  text-decoration: underline;
}
.Checkbox-input {
  margin-right: 8px;
}
.AnswerButton {
  display: flex;
  flex-direction: column;
  background-color: #E5E8E8;
  margin: 16px 0;
  padding: 16px;
}

.AnswerButton-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.AnswerButton-row--title {
  margin-right: 4px;
}

.AnswerButton-row--url {
  margin-left: 4px;;
}

.AnswerButton-actions {
  display: flex;
  justify-content: flex-end;
}

.AnswerButton-actions--delete:hover {
  color: #DD4F75;
  cursor: pointer;
}

@media (max-width: 960px) {
  .AnswerButton, .AnswerButton-row {
    flex-wrap: wrap;
  }

  .AnswerButton-row > * {
    margin-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.SimpleText {
  background-color: #E5E8E8;
  margin: 16px 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.SimpleText-delete {
  display: flex;
  justify-content: flex-end;
}

.SimpleText-delete:hover {
  cursor: pointer;
  color: #DD4F75;
}

.SimpleText-text {
  padding: 8px;
}

@media (max-width: 960px) {
  .SimpleText {
    flex-wrap: wrap;
  }
}

.QuickReply_old {
  display: flex;
  flex-direction: column;
  background-color: #E5E8E8;
  padding: 16px;
  margin: 16px 0;
}

.QuickReply_old-delete {
  display: flex;
  justify-content: flex-end;
}

.QuickReply_old-text {
  display: flex;
  padding: 8px;
}

.QuickReply_old-buttons {
  display: flex;
  flex-direction: column;
}

.QuickReply_old-buttons--button {
  display: flex;
  align-items: center;
  padding: 8px;
}

.QuickReply_old-buttons--button---reply {
  margin-right: 4px;
}

.QuickReply_old-buttons--button---payload {
  margin-left: 4px;
}

.QuickReply_old-buttons--button---delete {
  position: absolute;
  right: 22px;
  transition: all 0.2s;
}

.QuickReply_old-buttons--button---delete:hover,
.QuickReply_old-delete:hover {
  cursor: pointer;
  color: #DD4F75;
}

.QuickReply_old-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.QuickReply_old-actions--addQuickReply_old {
  color: rgb(59, 134, 255);
}

@media (max-width: 960px) {
  .QuickReply_old-actions {
    flex-wrap: wrap;
  }
}

.AnswerType {
  width: 10%;
  height: calc(100vh - 80px - 70px);
  position: fixed;
  display: flex;
}

.AnswerType ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.AnswerType li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #434B65;
}

.AnswerType-icon {
  font-size: 32px;
}

.Answers {
  display: flex;
}

.Answers-content {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 20%;
  background-color: #FFF;
  padding: 16px;
  width: calc(100% - 360px);
  box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 4px;
}

.Answers-content--submit {
  display: flex;
  justify-content: space-between;
}

.Answers-content--submit---cancelButton,
.Answers-content--submit---saveButton {
  width: 100px;
}

.Answers-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
}

.Message {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  max-width: 100%;
}

.Message-date {
  color: grey;
  margin: 1em 0 0;
  font-size: 80%;
  text-align: center;
  padding-top: 6px;
  margin-top: 20px !important;
  border-top: 1px solid #ddd;
  width: 100%;
}

.Message-date span {
  padding: 4px;
  color: #aaa;
  background-color: #fff;
  top: -15px;
  position: relative;
}

.Message.Message-user {
  flex-direction: row-reverse;
  text-align: right;
}

.Message .Message-text {
  position: relative;
}

.Message .Message-text img{
  max-width: 200px;
}

.Message .Message-text::before {
  position: absolute;
  right: auto;
  bottom: 0.6em;
  left: -12px;
  height: 0;
  content: '';
  border: 6px solid transparent;
  border-right-color: #eee;
}

.Message.Message-user .Message-text::before {
  right: -12px;
  bottom: 0.6em;
  left: auto;
  border: 6px solid transparent;
  border-left-color: #3b86ff;
}

.Message-text {
  background-color: #eee;
  padding: 0.5em;
  border-radius: 4px;
  font-weight: lighter;
  max-width: 70%;
}

.Message.Message-user .Message-text {
  border-top: 1px solid #3a86ff;
  border-bottom: 1px solid #3a86ff;
  background-color: #3a86ff;
  color: #fff;
}
.Message.Message-user .Message-payload-clicked {
  border-top: 1px solid #3a86ff;
  border-bottom: 1px solid #3a86ff;
  background-color: #3a86ff;
  color: #fff;
  padding: 3px 6px;
  border-radius: 3px;
}


.Message ul.Message-Qr {
  width: 100%;
  padding: 0;
  margin: 0;
}

.Message ul.Message-Qr li {
  padding: 3px 6px;
  display: inline-block;
  background-color: #a3a3a3;
  border-radius: 3px;
  color: white;
  margin: 5px;
  display: inline-block;
}

.Message-carrousel{
  width: 100%;
  overflow-x: overlay;
}

.Message-carrousel ul{
  margin: 0;
  padding: 0;
  display: block;
  width: -webkit-max-content;
  width: max-content;
}

.Message-carrousel ul li{
  width: 250px;
  float: left;
  display: block;
  margin: 10px 20px 20px 0px;
  background-color: #fff;
  padding: 0px;
  border: 1px solid #efefef;
  border-radius: 15px;
  overflow: hidden;
}

.Message-carrousel ul li h3{
  color: #4e5058;
  margin: 10px;
}

p{
  color: #4d5057;
  margin: 5px 10px;
  font-size: 0.9em;
  line-height: 1.3;
}


.Message-carrousel ul li img{
  object-fit: cover;
  width: 100%;
  height: 125px;
}

.Message-carrousel-card{
  width: 180px;
  display: inline-block;
}

.Message-buttons{
  padding: 4px;
  min-width: 200px;
  margin-left: 10px;
  border-radius: 4px;
  background-color: rgb(239, 239, 239);
}

.Message-buttons-container{
  padding: 5px 15px;
  background-color: rgb(239, 239, 239);
}

.Message-buttons-container span{
  font-size: 0.95em;
  display: block;
  text-align: center;
  padding: 2px;
  margin: 10px 0px;
  background-color: rgb(252, 252, 252);
  border-radius: 5px;
  border: 1px solid transparent;
}



.Conversation .Message {
  margin: 0.5em;
}

.Conversation {
  flex-shrink: 0;
  height: 100%;
  background-color: white;
  padding: 1em 1em 10em 1em;
  margin-top: -0.5%;
  overflow-y: scroll;
}

.SearchBar {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-around;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.ConversationsFilter {
}

.ConversationsFilter-top {
    background: #fff;
    padding: 0.5em;
    margin-top: 1em;
    min-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    border-radius: .3em;
}

.ConversationsFilter-bottom>.download-button {
    background: #f9f9f9;
}

.ConversationsFilter-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.ui.small.active.inline.loader.download-loading {
    padding: 8px 18px;
    margin: 0.5em 0.7em;
}

.PageChanger {
  width: 95%;
  padding: 0.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em;
  justify-content: flex-end;
}

.PageChanger-container {
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
  align-items: center;
  width: 8em;
  justify-content: space-around;
}

.Button.ChangePageArrow {
  background: #f9f9f9;
  padding: 0.1em;
}

.Button.Button-disabled.ChangePageArrow {
  color: #8a8a8a;
}


.Conversations {
  margin: 20px 30px 120px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Conversations-content--container {
  min-height: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Conversations-content--list {
  height: 750px;
  width: 20%;
  min-width: 10%;
  flex-grow: 1;
  margin-right: 1em;
  overflow-y: scroll;
}

.Conversations-content--messages {
  width: 80%;
  height: 750px;
  display: flex;
  flex-direction: column;
}

.Conversations-emptyState-container div.emptyElement-container {
  border: none;
}

.Conversations-emptyState-container div.emptyElement-second.emptyContent {
  display: none;
}

.Conversations-emptyState-container div.emptyElement-first.emptyContent {
  width: 8em;
}

.Conversations-emptyState-container div:nth-child(1)>div.emptyElement-second.emptyContent,
.Conversations-emptyState-container div:nth-child(3)>div.emptyElement-second.emptyContent,
.Conversations-emptyState-container div:nth-child(4)>div.emptyElement-second.emptyContent {
  top: 2em;
  height: 3em;
  width: 12em;
  display: initial;
  position: absolute;
  right: 2em;
}
.Conversations-emptyState-container div:nth-child(2)>div.emptyElement-second.emptyContent {
  height: 6em;
  width: 24em;
  display: initial;
  position: absolute;
  top: 6em;
  right: 2em;
}

.Conversations-emptyState-container div:nth-child(3)>div.emptyElement-second.emptyContent {
  top: 13em;
  right: 10em;
  width: 40em;
}
.Conversations-emptyState-container div:nth-child(4)>div.emptyElement-second.emptyContent {
  top: 17em;
}

.Conversations-emptyState-container {
  margin-top: 2em;
}

.AddSynonym-TextInput {
    width: 50%;
    max-width: 400px;
    margin-bottom: 14px;
}

.AddSynonym-AddIcon {
    color: rgba(34,36,38,.15);
}

.AddSynonym-TextInput > .TextInput-container:focus-within .AddSynonym-AddIcon {
    color: #96c8da;
}
.Synonym-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.Synonym-list > li {
    list-style: none;
}

.Synonym-list > *:not(:last-child) {
    margin-bottom: 2px;
}

.Synonym-row {
    background-color: #F2F2F2;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.Synonym-row > li:first-child {
    flex-grow: 1;
    padding-left: 0;
}

.Synonym-row > li {
    list-style: none;
    padding-left: 10px;
    align-self: center;
}

.Synonym-row .TextInput-container.TextInput-disabled {
    background: none;
}

.Synonym-row--icon-old {
    color: #979797;
    margin-left: auto;
    cursor: pointer;
}

.ui.active.inline.loader.Synonym-row--loading {
    margin-left: auto;
    right: 0;
}

.Counter {
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1em;
    height: 1em;
    text-align: center;
}

.Counter > span {
    display: inline-block;
    align-self: center;
    line-height: normal;
    vertical-align: middle;
    margin: 0 2px;
}

.Counter-flag {
    font-size: 1.5em;
}

.Counter-label {
    background: #d6d6d6;
    padding: 2px 10px;
    border-radius: 10px;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 0.9em
}

.EnabledState {
    padding: 2px;
}

.EnabledState > * {
    vertical-align: middle;
}

.EnabledState-enabled {
    color: #79d747;
}

.EnabledState-disabled {
    color: #d6d6d6
}

.OpenState {
    padding: 2px;
}

.OpenState > * {
    vertical-align: middle;
}

.EntityBreadcrumb {
    margin: 10px;
    color: #434B65;
    font-size: 1.2em;
    line-height: 1.5em;
    font-family: 'Open Sans', Helvetica, Arial;
    font-weight: 100;
    display: flex;
    align-items: center;
}

.EntityBreadcrumb-path {
    display: flex;
    align-items: center;
}

.EntityBreadcrumb-link-separator {
    margin: 0 3px;
}

.EntityBreadcrumb-link {
    color: rgba(67, 75, 101, 1);
    font-weight: 900;
}

.EntityBreadcrumb > *:not(:first-child) {
    margin-left: 10px;
    display: flex;
}

.EntitySettings {
  display: flex;
  flex-direction: column;
}

.EntitySettings h1 {
  align-self: center;
}

.EntitySettings-setting {
  padding: 16px 0;
}

.ui.tabular.menu > .item.Entities-tabItem {
    font-size: 1.5em;
    padding: 0 10px;
}
.Entity-content {
    display: flex;
}

.Entity-content--tab {
    flex-grow: 5;
}

.Entity-content--settings {
    flex-grow: 2;
    padding: 16px 32px;
    margin-left: 10px;
}

.LabeledName {
    background: #3B86FF;
    border-radius: 15px;
    color: white;
    padding: 5px 15px;
    cursor: pointer;
}

.Entities {
    margin: 20px;
    /*border: solid #dadada 1px;*/
}

.Entities-actionBar {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
}

.Entities-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.Entities-list > li {
    list-style: none;
    border-bottom: solid #dadada 1px;
}

.Entities-list > li:last-child {
    border: none;
}

.Entities-row {
    background: white;
    padding: 14px;
    display: flex;
    flex-direction: row;
}

.Entities-row > li {
    list-style: none;
    align-self: center;
    padding: 0 10px;
}

.Entities-row > li:first-child {
    flex-grow: 1
}

.CreateEntity-actions {
    display: flex;
    justify-content: flex-end;
}

.appsettings {
  max-width: 48.813em;
}

.appsettings__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.appsettings__content {
  padding: 1.8em 2em;
  border-radius: 8px;
  border: solid 1px #e7e7e7;
  background-color: #ffffff;
}

.appsettings__title {
  font-size: 1.75em;
  color: #434b65;
}

.appsettings__subtitle {
  font-size: 1em;
  font-weight: normal;
  color: #bababa;
}

.appsettings__subtitlelink {
  text-decoration: underline;
  color: inherit;
}

.appsettings__inputlabel {
  font-size: 0.765em;
  font-weight: bold;
  color: #1a2b63;
}

.appsettings__input {
  display: block;
  width: 100%;
  padding: 0.75em 1em;
  border-radius: 6.5px;
  border: solid 0.7px #ececec;
  background-color: #ffffff;
  font-size: 0.765em;
  color: #434b65;
}

.appsettings__checkbox{
  float: right;
}
.DateInput_input {
  width: 75px;
  font-size: 1em;
  background-color: #3B86FF;
  color: white;
  font-family: 'Open Sans', Helvetica, Arial;
  padding: 5px 0px;
  vertical-align: middle;
  border: 0px;
  height: 20px;
}

.DateInput_input::-webkit-input-placeholder {
  color: rgb(190, 190, 190);
}

.DateInput_input::placeholder {
  color: rgb(190, 190, 190);
}

.DateInput_input__focused_2 {
  color: white;
}

.DateRangePickerInput {
  border: 0px;
  padding: 5px;
  width: 310px;
  background-color: #3B86FF;
  height: 28px;
  display: flex;
  align-items: center;
}

.DateRangePickerInput_arrow_svg {
  color: white;
  fill: white;
  width: 50px;
  margin-right: 20px;
}

.DateInput_displayText__focused {
  background-color: #3B86FF;
}

.DateRangePicker_picker__openDown {
  top: 50px;
}

.DateInput__openDown:after, .DateInput__openDown:before {
  display: none;
}

.DayPicker_weekHeader {
  top: 55px;
}

.DateInput_input {
  cursor: pointer;
}

.CalendarDay__selected_span {
  background-color: #3B86FF;
  border-color: #3B86FF;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  background-color: #3B86FF;
  border-color: #3B86FF;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background-color: #3B86FF;
  border-color: #3B86FF;
}

.DateRangePickerInput_calendarIcon_svg, .DateRangePickerInput_clearDates_svg {
  fill: white;
  margin-bottom: 2px;
}

.DateRangePickerInput_calendarIcon {
  padding-right: 15px;
}

.DateRangePickerInput_clearDates_default:focus, .DateRangePickerInput_clearDates_default:hover {
  background-color: transparent;
}

.DateRangePickerInput_clearDates_svg:hover {
  fill: white;
}

.ui.inverted.blue.active.button, .ui.inverted.blue.buttons .active.button {
  background-color: #3B86FF;
}

.ui.inverted.blue.button:hover, .ui.inverted.blue.buttons .button:hover{
  background-color: #3B86FF;
}

.ui.inverted.blue.button, .ui.inverted.blue.buttons .button{
  box-shadow: 0 0 0 2px #3B86FF inset !important;
  color: #3B86FF;
  font-weight: lighter;
  border-width: 1px;
}

body {
    color: #3f4047;
    font-family: 'Open Sans', Helvetica, Arial;
}

h1 {
    color: #434B65;
    font-size: 16px;
    font-family: 'Open Sans', Helvetica, Arial;
}

h2 {
    color: #434B65;
    font-size: 15px;
}

h3 {
    color: #A7B1D1;
    font-size: 13px;
}

/*For big figures*/
h6 {
  color: #434B65;
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.ui.blue.buttons .button  {
  background-color: #3B86FF;
}

.ui.blue.button {
  background-color: #3B86FF;
}

.ui.blue.button:hover {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.ui.blue.buttons .button:hover {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.ui.blue.buttons .active.button {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.ui.blue.buttons .active.button:active {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.moreinfoicon {
    margin-left: 5px;
    margin-top: 0px;
    background-image: url(/static/media/hyApxGOztoJ.a9d1668d.png);
    background-size: 51px 960px;
    background-position: 0 -692px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 16px;
    height: 15px;
}

.containerChat {
  width: 100%;
}

.user {
  text-align: right;
}

body {
    color: #3f4047;
    font-family: 'Open Sans', Helvetica, Arial;
}

h1 {
    color: #434B65;
    font-size: 16px;
    font-family: 'Open Sans', Helvetica, Arial;
}

h2 {
    color: #434B65;
    font-size: 15px;
}

h3 {
    color: #A7B1D1;
    font-size: 13px;
}

/*For big figures*/
h6 {
  color: #434B65;
  font-size: 30px;
}

.ui.blue.buttons .button  {
  background-color: #3B86FF;
}

.ui.blue.button {
  background-color: #3B86FF;
}

.ui.blue.buttons .button:hover {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.ui.blue.buttons .active.button {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.ui.blue.buttons .active.button:active {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.moreinfoicon {
  margin-left: 5px;
  margin-top: 0px;
  background-image: url(/static/media/hyApxGOztoJ.a9d1668d.png);
  background-size: 51px 960px;
  background-position: 0 -692px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 16px;
  height: 15px;
}

.containerChat {
  width: 100%;
}

.user {
  text-align: right;
}

body {
    color: #3f4047;
    font-family: 'Open Sans', Helvetica, Arial;
}

h1 {
    color: #434B65;
    font-size: 16px;
    font-family: 'Open Sans', Helvetica, Arial;
}

h2 {
    color: #434B65;
    font-size: 15px;
}

h3 {
    color: #A7B1D1;
    font-size: 13px;
}

/*For big figures*/
h6 {
  color: #434B65;
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.ui.blue.buttons .button  {
  background-color: #3B86FF;
}

.ui.blue.button {
  background-color: #3B86FF;
}

.ui.blue.button:hover {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.ui.blue.buttons .button:hover {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.ui.blue.buttons .active.button {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.ui.blue.buttons .active.button:active {
  background-color: #C6DAFC;
  color: #3B86FF;
}

.moreinfoicon {
    margin-left: 5px;
    margin-top: 0px;
    background-image: url(/static/media/hyApxGOztoJ.a9d1668d.png);
    background-size: 51px 960px;
    background-position: 0 -692px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 16px;
    height: 15px;
}

.containerChat {
  width: 100%;
}

.user {
  text-align: right;
}

.ui.progress:last-child {
  margin-bottom: 0px;
}

.link-intents-table {
  color: #F9F9F9;
  background-color: #3B86FF;
  padding: 5px 15px;
  border-radius: 15px;
}

.link-intents-table:hover {
  color: white;
  background-color: #87B5FF;
  padding: 5px 15px;
  border-radius: 15px;
}

.ui.sortable.table thead th {
  background-color: #f9F9F9;
  border: none;
}

.ui.sortable.table thead th:first-child {
  width: 20%;
}

.ui.sortable.table thead th:nth-child(3) {
  width: 15%;
}

.ui.sortable.table thead th:last-child {
  width: 10%;
}

.ui.table {
  border: none;
}

.ui.table tr td {
  padding-top: 14px;
  padding-bottom: 14px;
}

.MainPage-createTagZone{
  display: flex;
  justify-content: flex-end;
}

.MainPage-dropZoneContainer {
  display: flex;
  justify-content: center;
  margin-top: 95px;
}

.MainPage-dropzone {
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border-color: rgb(210, 210, 210)!important;
  transition-duration: 0.2s;

  position: relative;
  width: 200px;
  height: 200px;
  border-width: 2px;
  border-style: dashed;
  border-radius: 5px;
}

.MainPage-dropzone:focus {
  outline: none;
}

.MainPage-dropzone.dropzone-active {
  border-style: solid;
  background-color: rgb(238, 238, 238);
}

.MainPage-dropzone:hover {
  cursor: hand;
  cursor: pointer;
  border-color: rgb(150, 150, 150)!important;
}

.MainPage-dropzone:hover p {
  color: rgb(150, 150, 150);
}

.MainPage-dropzone:hover i.icon, i.icons {
  color: rgb(150, 150, 150);
}

.MainPage-dropzone i.icon, i.icons {
  color: rgb(210, 210, 210);
  margin-bottom: 10px;
  transition-duration: 0.2s;
}

.MainPage-dropzone p {
  font-family: 'Open Sans', Helvetica, Arial;
  color: rgb(210, 210, 210);
  transition-duration: 0.2s;
}

.MainPage-botNeedsTrain {
  margin-top: 22px;
  text-align: center;
}

.MainPage-botNeedsTrain p {
  font-size: 32px;
  font-family: 'Open Sans', Helvetica, Arial;
  color: rgb(220, 220, 220);
}




.AddAnswerForm {
  margin-top: 33px;
}

.AddAnswerForm-select {
  margin-bottom: 18px;
}

.TrainingPanel-customAnswer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.TrainingPanel-customAnswer--actions {
  font-size: 16px;
}

.TrainingPanel-customAnswer--actions---edit {
  margin-right: 8px;
  cursor: pointer;
  transition: color 0.2s;
}

.TrainingPanel-customAnswer--actions---edit:hover {
  cursor: pointer;
  color: rgb(59, 134, 255);
}

.TrainingPanel-customAnswer--actions---trash {
  margin-right: 8px;
  cursor: pointer;
  transition: color 0.2s;
}

.TrainingPanel-customAnswer--actions---trash:hover {
  color: rgb(243, 44, 46);
  cursor: pointer;
}

.TrainingPanel-publish {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}

.TrainingPanel-publish--button {
  color: rgb(249, 249, 249);
  background-color: rgb(59, 134, 255);
  font-family: 'Open Sans', Helvetica, Arial;
  font-weight: lighter;
  border: none;
  border-radius: 4px;
  width: 100px;
  margin-top: 16px;
}

.TrainingPanel-failure, .TrainingPanel-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#intent-title-builder {
  color: #434B65;
  font-size: 22px;
  font-family: 'Open Sans', Helvetica, Arial;
  font-weight: 100;
}

#intent-description-builder {
  color: #434B65;
  font-size: 16px;
  font-family: 'Open Sans', Helvetica, Arial;
  font-style: italic;
  font-weight: lighter;
  display: inline;
}

#global-intent-description-builder {
  width: 70%;
}

#global-intent-description-builder:hover > #icon-edit-description, #global-intent-name-builder:hover > #icon-edit-name {
  visibility: visible;
  opacity: 1;
}

#icon-edit-description, #icon-edit-name {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.1s linear;
}

#icon-edit-description:hover, #icon-edit-name:hover {
  cursor: pointer;
  color: rgba(67, 75, 101, 0.4);
}

.ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child {
  color: #434B65;
}

#link-to-intents {
  color: rgba(67, 75, 101, 1);
  font-size: 22px;
  font-family: 'Open Sans', Helvetica, Arial;
  font-weight: 900;
}

#link-to-intents:hover {
  color: rgba(67, 75, 101, 0.7);
  font-size: 22px;
  font-family: 'Open Sans', Helvetica, Arial;
  font-weight: 900;
}

.DateInput {
  width: 62px;
  font-size: 1em;
  padding: 3px;
  line-height: 13px;
  background-color: #3B86FF;
}

.DateRangePickerInput {
  border-radius: .28571429em;
  border: 0px;
  background-color: #3B86FF;
  padding: 1px;
}

.DateInput_displayText__hasInput, .DateRangePickerInput_arrow_svg {
  color: white;
  fill: white;
}

.DateInput_displayText__focused {
  background-color: #3B86FF;
}

.DateRangePicker_picker__openDown {
  top: 50px;
}

.DateInput__openDown:after, .DateInput__openDown:before {
  display: none;
}

.DayPicker_weekHeader {
    top: 55px;
}

.DateInput_input {
  cursor: pointer;
}

.CalendarDay__selected_span {
  background-color: #c6dafc;
  border-color: #c6dafc;
  color: #3B86FF;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  background-color: #3B86FF;
  border-color: #3B86FF;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background-color: #3B86FF;
  border-color: #3B86FF;
  color: white
}

.ui.search.dropdown>.text {
  color: #434B65;
}

.ui.search.dropdown>.dropdown.icon:before {
  color: #434B65 !important;
}

.TrainingFilters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  min-height: 64px;
}

.TrainingFilters-filter:nth-child(n+2) {
  margin-left: 16px;
} 

.ui.checkbox .box:before, .ui.checkbox label:before, .ui.checkbox .box:after, .ui.checkbox label:after {
  height: 24px;
  width: 24px;
  font-size: 17px;
}

.ui.checkbox {
  min-height: 24px;
  min-width: 24px;
}

.ui.checkbox label:after {
  margin-top: 3px;
}

.ui.checkbox input:focus~.box:before, .ui.checkbox input:focus~label:before {
  border-color: #3B86FF;
}

.ui.checkbox input:checked:focus~.box:before, .ui.checkbox input:checked:focus~label:before, .ui.checkbox input:not([type=radio]):indeterminate:focus~.box:before, .ui.checkbox input:not([type=radio]):indeterminate:focus~label:before {
  border-color: #3B86FF;
}

.ConversationsList-global {
  display: flex;
  max-width: 500px;
  padding: 20px 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: white;
  border: 1px lightgrey solid;
  align-items: center;
}

.ConversationsList-global > *:not(:last-child) {
  margin-right: 20px;
}

.ConversationsList-global--intent {
  flex: 1 1;
}

.ConversationsList-listItem {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.ConversationsList-listItem--intent {
  width: 17%;
}

.ConversationsList-listItem--intent-label {
  background-color: #3B86FF;
  border-radius: 15px;
  padding: 4px 16px;
  color: white;
  margin-left: 10px;
  display: inline-block;
}

.ConversationsList-listItem--text {
  width: 50%;
  margin: 0 10px;
}

.ConversationsList-listItem--text-annotations {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.ConversationsList-listItem--text-annotations > *:not(:last-child) {
  margin-right: 6px;
}

.ConversationsList-listItem--text-flag {
  font-size: 1.4em;
  vertical-align: initial;
  text-decoration: inherit;
}

.ConversationsList-listItem--text-annotations-image {
  height: 16px;
  margin-right: 5px;
}

.ConversationsList-listItem--text-annotations-date {
  width: auto;
  font-size: 9px;
  color: #8080807a;
}

.ConversationsList-listItem--train {
  margin-right: 20px;
  color: #4888F7;
}

.TestPanel-button {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 10;
  height: 50px;
  width: 145px;
  font-family: 'Open Sans', Helvetica, Arial;
  background-color: #3B86FF;
  color: #f9f9f9;
  border-top-left-radius: 2px;
  border-style: none;
  cursor: pointer;
}

.TestPanel-input {
  background-color: lightgrey;
  position: fixed;
  bottom: 0px;
  right: 145px;
  height: 50px;
  width: 255px;
  border-style: none;
  padding-left: 15px;
  font-family: 'Open Sans', Helvetica, Arial;
  border-bottom: 4px solid #3B86FF;
  cursor: text;
}

.TestPanel-answer {
  padding: 11px 35px;
  border-radius: 20px;
  background-color: #3B86FF;
  color: #F9F9F9;
  position: fixed;
  top: 210px;
  right: 30px;
}

#enter-test {
  position: fixed;
  bottom: 100px;
  right: 0px;
  z-index: 1;
  height: 50px;
  width: 145px;
  font-family: 'Open Sans', Helvetica, Arial;
  background-color: #3B86FF;
  color: #f9f9f9;
  border-top-left-radius: 2px;
  border-style: none;
  cursor: pointer;
}

.TestPanel-text {
  padding: 11px 35px;
  border-radius: 20px;
  background-color: #3B86FF;
  color: #F9F9F9;
  position: absolute;
  top: 50px;
}

.Log {
  display: flex;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  /* justify-content: space-between; */
}

.Log-login {
  text-decoration: underline;
}

.Log-createdAt {
  font-weight: 800;
}
.Paginator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Paginator-counter {
  padding: 16px;
}

.Paginator-button {
  width: 120px;
}

.Tag {
  display: flex;
  align-items: center;
  background-color: #F2F2F2;
  padding: 2px 4px;
  margin: 2px 0;
  border-radius: 4px;
  font-size: 0.9em;
  font-family: 'Open Sans', Helvetica, Arial;
}

.Tag-iconDelete {
  font-size: 0.9em;
  cursor: pointer;
  padding-right: 4px;
  color: #828282;
}
.MultiSelect {
  position: relative;
  min-width: 250px;
}

.MultiSelect-control {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, .15);
  line-height: 1.21428571em;
  padding: .67857143em 2.1em .67857143em 1em;
  min-height: 36px;
}

.MultiSelect-control--selectedText {
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.MultiSelect-control--selectedText---text {
  text-overflow: ellipsis;
}

.MultiSelect-control--faCaret {
  position: absolute;
  right: 12px;
  font-size: 12px;
  opacity: 0.7;
  cursor: pointer;
}

.MultiSelect-control--open {
  border-color: #96c8da;
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.MultiSelect-control--error {
  border-color: #F76A72;
}

.MultiSelect-control--placeholder {
  color: rgba(191,191,191,.87);
}

.MultiSelect-control:hover {
  border-color: rgba(100,100,100,.6);
}

.MultiSelect-control--open:hover {
  border-color: #96c8da;
}

.MultiSelect-options {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  border: 1px solid #96c8da;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
  max-height: 180px;
  overflow-y: auto;
}

.MultiSelect-options::-webkit-scrollbar-thumb {
  background-color: #2185d0;
}

.MultiSelect-options::-webkit-scrollbar-track {
  background-color: #F8F8F8;
}

.MultiSelect-wrapper {
  position: relative;
}

.MultiSelect-textInput {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  height: 32px;
  border-top: 1px solid #F2F2F2;
  border-left: 1px solid #96c8da;
  border-right: 1px solid #96c8da;
}

.MultiSelect-textInput:focus {
  border: 1px solid #F2F2F2;
  border-right-color: #96c8da;
  border-left-color: #96c8da;
}

.MultiSelect-control--tag {
  margin-right: 4px;
}

.MultiSelect-formError {
  background-color: transparent;
  margin-left: 12px;
}

.Form > * {
  margin: 16px 0;
}

.Form-submitBar {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
}

.UserForm {
  display: flex;
  justify-content: center;
  flex: 1 1;
}

.UserForm-form {
  margin-top: 32px;
  flex: 0.6 1;
}

.Profile-text {
  font-family: 'Open Sans', Helvetica, Arial;
  font-weight: bold;
  font-size: 14px;
  color: #434b65;
}

.Profile-icon {
  position: relative;
  right: 25px;
  top: 14px;
  color: #ececec;
}

.Profile-icon-active {
  color: #3a86ff;
}

.Profile-input {
  width: 100%;
}

.Profile-input .ui.input input {
  border-radius: 8px;
}

.Profile-button {
  display: flex;
}

.PasswordPage .ui.form .field>label {
  display: none;
}

.PasswordPage .ui.form>:first-child {
  margin-bottom: 20px;
}

.PasswordPage .ui.form .field {
  padding-bottom: 4px;
}

.PasswordPage .ui.button {
  background: #6ba4ff;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.PasswordPage .ui.button>p {
  padding-right: 10px;
  margin: 0;
}

.PasswordField-container {
  position: relative;
}

.PasswordField-icon {
  position: absolute;
  left: 98%;
  top: 10px;
}

.Profile-password-instruction {
  font-style: italic;
  font-size: 12px;
}
#title-release {
  font-family: 'Open Sans', Helvetica, Arial;
  font-size: 35px;
  font-weight: 500;
  padding: 25px 0px;
  margin-bottom: 10px;
}
.release-title {
  font-family: 'Open Sans', Helvetica, Arial;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}
.release-subtitle {
  font-family: 'Open Sans', Helvetica, Arial;
  font-size: 17px;
  font-weight: 400;
}
#release-feedback {
  background-color: white;
  padding: 15px;
  width: 600px;
  border-radius: 2px;
}

.navbar {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  width: 214px;
}

.navbar > nav {
  height: 100%;
  width: 214px !important;
}

.navbar nav ul::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

.topbar-right-content__dropdown-menu button span span {
  max-width: 10rem;
}

.topbar-right-content__dropdown-menu-list {
  width: 12rem;
  left: unset;
  right: 0
}
.topbar-left-content__dropdown-bot {
  margin-right: var(--spacing-xxl);
}

.topbar-left-content__dropdown-bot button span span {
  max-width: 10rem;
}

.topbar-left-content__dropdown-bot-list {
  max-height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 25rem;
}

.topbar-left-content__bot-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.bot-link__label {
  font-family: Rubik;
  font-size: 1rem;
  line-height: 1.38;
  color: var(--gray-700);
  margin-right: var(--spacing-xs)
}

.topbar-left-content__bot-link:hover .bot-link__label {
  text-decoration: underline;
}

.bot-link__icon {
  width: 1rem;
  height: 1rem;
  color: var(--gray-700);
  flex-shrink: 0;
}
/* ============================== */

.ui-library-topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
}

.ui-library-topbar > span {
  height: 80px;
}

.ui-library-topbar__home-button {
  outline: none;
  background: unset;
  border: none;
  cursor: pointer;
}
body {
  height: calc(100% - 70px);
  /* This value correspond to the height of the topBar (which is fixed)*/
  background-color: #F9F9F9;
  font-size: 14px
}

html, #root {
  height: 100%;
}

html {
  font-size: 16px
}

.primary {
  background-color: #3B86FF;
  color: #FFF;
}

.DateRangePickerInput {
  height: 36px;
}

.pageContainer {
  padding: 20px 30px 120px;
}

.ui.bottom.attached.segment.active.tab {
  min-height: 200px;
}

.ui.toggle.checkbox input:checked~.box:after,
.ui.toggle.checkbox input:checked~label:after,
.ui.toggle.checkbox input~.box:after,
.ui.toggle.checkbox input~label:after {
  margin-top: 0px;
}

/* I have no choice but to use this shitty important because
the toggle button in react semantic ui don't provide a style props and include a native !important
*/
.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
  background-color: #3b86ff !important ;
}

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #3b86ff !important;
}


@font-face {
  font-family: Rubik;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src:
    local(rubik-light),
    url(/static/media/Rubik-Light.6515f045.woff2) format('woff2'),
    url(/static/media/Rubik-Light.3485e96a.woff) format('woff'),
    url(/static/media/Rubik-Light.7a045637.ttf) format('truetype');
}

@font-face {
  font-family: Rubik;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    local(rubik-regular),
    url(/static/media/Rubik-Regular.8a048451.woff2) format('woff2'),
    url(/static/media/Rubik-Regular.499961d2.woff) format('woff'),
    url(/static/media/Rubik-Regular.b1e10622.ttf) format('truetype');
}

@font-face {
  font-family: Rubik;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src:
    local(rubik-medium),
    url(/static/media/Rubik-Medium.e71b678a.woff2) format('woff2'),
    url(/static/media/Rubik-Medium.8674913c.woff) format('woff'),
    url(/static/media/Rubik-Medium.69446878.ttf) format('truetype');
}

