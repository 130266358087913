.FormLabel-label {
  display: block;
  margin-bottom: 4px;
  font-weight: 800;
  color: #565D74;
}

.FormLabel-required {
  color: #F76A72;
  margin-left: 5px;
  font-weight: 800;
}
