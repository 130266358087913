.Button {
  display: flex;
  padding: 8px 18px;
  cursor: pointer;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  border: none;
  outline: 0;
  justify-content: center;
  opacity: 0.75;
}
.Button:hover {
  opacity: 1;
}

.Button-default {
  border-radius: 8px;
}

.Button-rounded {
  border-radius: 16px;
}

.Button-disabled {
  opacity: 0.6;
  cursor: default;
}
.Button-disabled:hover {
  opacity: 0.6;
}

.Button-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.Button:focus {
  outline: 0;
}
