.ButtonLink {
  display: inline-block;
  padding: 8px 18px;
  cursor: pointer;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  border: none;
  outline: 0;
  justify-content: center;
  opacity: 0.75;
}

.ButtonLink:hover {
  opacity: 1;
}

.ButtonLink-default {
  border-radius: 8px;
}

.ButtonLink-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.ButtonLink:focus {
  outline: 0;
}