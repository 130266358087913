.TrainingPanel-customAnswer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.TrainingPanel-customAnswer--actions {
  font-size: 16px;
}

.TrainingPanel-customAnswer--actions---edit {
  margin-right: 8px;
  cursor: pointer;
  transition: color 0.2s;
}

.TrainingPanel-customAnswer--actions---edit:hover {
  cursor: pointer;
  color: rgb(59, 134, 255);
}

.TrainingPanel-customAnswer--actions---trash {
  margin-right: 8px;
  cursor: pointer;
  transition: color 0.2s;
}

.TrainingPanel-customAnswer--actions---trash:hover {
  color: rgb(243, 44, 46);
  cursor: pointer;
}

.TrainingPanel-publish {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}

.TrainingPanel-publish--button {
  color: rgb(249, 249, 249);
  background-color: rgb(59, 134, 255);
  font-family: 'Open Sans', Helvetica, Arial;
  font-weight: lighter;
  border: none;
  border-radius: 4px;
  width: 100px;
  margin-top: 16px;
}

.TrainingPanel-failure, .TrainingPanel-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
}
