.Paginator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Paginator-counter {
  padding: 16px;
}

.Paginator-button {
  width: 120px;
}
