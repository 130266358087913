.DateRangeComponent .DateInput {
    background: #3B86FF;
}

.DateRangeComponent .DateInput_input {
    background-color: #3B86FF;
    color: white;
    padding: 5px 0px;
    vertical-align: middle;
}

.DateRangeComponent .DateInput_input__focused_2 {
    color: white;
}

.DateRangeComponent .DateRangePickerInput {
    width: 310px;
    background-color: #3B86FF;
    height: 2.5em;
    display: flex;
    align-items: center;
}

.DateRangeComponent .DateRangePickerInput_arrow_svg {
    fill: white;
    width: 50px;
    margin-right: 20px;
}

.DateRangeComponent .DateInput_displayText__focused {
    background-color: #3B86FF;
}

.DateRangeComponent .CalendarDay__selected_span {
    background-color: #C6DAFC;
    border-color: #C6DAFC;
    color: #3B86FF;
}

.DateRangeComponent .CalendarDay__selected,
.DateRangeComponent .CalendarDay__selected:active,
.DateRangeComponent .CalendarDay__selected:hover,
.DateRangeComponent .CalendarDay__selected_span:active,
.DateRangeComponent .CalendarDay__selected_span:hover,
.DateRangeComponent .CalendarDay:hover {
    background-color: #3B86FF;
    border-color: #3B86FF;
    color: white;
}

.DateRangeComponent .DateRangePickerInput_calendarIcon_svg,
.DateRangeComponent .DateRangePickerInput_clearDates_svg {
    fill: white;
    margin-bottom: 2px;
}

.DateRangeComponent .DateRangePickerInput_clearDates_default:focus,
.DateRangeComponent .DateRangePickerInput_clearDates_default:hover {
    background-color: transparent;
}

